import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    search: '',
    sort_by: {
      name: '',
    },
    roles: [],
    teams: [],
  },
  selectedMembers: [],
  members: [],
};

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setSelectedMembers: (state, action) => {
      state.selectedMembers = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
  },
});

export const { setSearch, setFilters, setSelectedMembers, setMembers } = membersSlice.actions;

export default membersSlice.reducer;
