import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Typography, Button } from '@mui/material';
import { TopBar } from 'components/TopBar/TopBar';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { Loader } from 'components/Loader';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { MoreThanIcon, CloseIcon, ArrowLeftIcon, SquareArrow } from 'components/Icons';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { Card } from 'components/Card';
import { useStyles } from '../styles';
import { getCreditDetail, setSearchDetail, setPageFilterDetail } from 'slices/creditUsageReducer';
import { Bytes } from 'lib/bytes';
import { theme } from 'theme';
import moment from 'moment';
import exportFromJSON from 'export-from-json';
import SplashImage from 'images/ContentSplash.png';

// data
const processingRows = [
  {
    org_name: '',
    proj_name: '',
    asset_name: '',
    fileSize: '',
    uploaded_by: '',
    credits: '',
  },
];

const storageRows = [
  {
    org_name: '',
    proj_name: '',
    no_assets: '',
    near_storage: '',
    cold_storage: '',
    credits: '',
  },
];

const creditsRows = [
  {
    org_name: '',
    added_by: '',
    credits: '',
  },
];

const archiveViewRows = [
  {
    org_name: '',
    proj_name: '',
    asset_name: '',
    restored_by: '',
    credits: '',
  },
];

const defaultRows = [
  {
    foo: 'bar',
  },
];

// processing columns
const tableProcessingColumns = () => [
  {
    Header: 'Org Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.org_name}
      </div>
    ),
  },
  {
    Header: 'Project Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.proj_name}
      </div>
    ),
  },
  {
    Header: 'Asset Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.asset_name}
      </div>
    ),
  },
  {
    Header: 'File Size',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {Bytes.dynamicHumanReadable({ bytes: row.file_size })}
      </div>
    ),
  },
  {
    Header: 'Uploaded By',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.uploaded_by}
      </div>
    ),
  },
  {
    Header: 'Credits',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.credits.toLocaleString('en-US', {
          numberingSystem: true,
          maximumFractionDigits: 2,
        })}
      </div>
    ),
  },
];

// storage columns
const tableStorageColumns = () => [
  {
    Header: 'Org Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.org_name}
      </div>
    ),
  },
  {
    Header: 'Project Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.proj_name}
      </div>
    ),
  },
  {
    Header: 'No. of Assets',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.no_assets}
      </div>
    ),
  },
  {
    Header: 'Near Storage',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {Bytes.dynamicHumanReadable({ bytes: row.near_storage })}
      </div>
    ),
  },
  {
    Header: 'Cold Storage',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {Bytes.dynamicHumanReadable({ bytes: row.cold_storage })}
      </div>
    ),
  },
  {
    Header: 'Credits',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.credits.toLocaleString('en-US', {
          numberingSystem: true,
          maximumFractionDigits: 2,
        })}
      </div>
    ),
  },
];

// credit columns
const tableCreditColumns = () => [
  {
    Header: 'Org Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.org_name}
      </div>
    ),
  },
  {
    Header: 'Added By',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.added_by}
      </div>
    ),
  },
  {
    Header: 'Credits',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.credits}
      </div>
    ),
  },
];

// archive view columns
const tableArchiveViewColumns = () => [
  {
    Header: 'Org Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.org_name}
      </div>
    ),
  },
  {
    Header: 'Project Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.proj_name}
      </div>
    ),
  },
  {
    Header: 'Asset Name',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.asset_name}
      </div>
    ),
  },
  {
    Header: 'Restored By',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.restored_by}
      </div>
    ),
  },
  {
    Header: 'Credits',
    accessor: (row) => (
      <div
        style={{
          fontFamily: 'inter',
          color: theme.palette.primary[400],
          fontSize: '14px',
          fontStyle: 'normal',
        }}
      >
        {row.credits}
      </div>
    ),
  },
];

const defaultColumns = () => [
  {
    Header: 'Column',
    accessor: (row) => row.foo,
  },
];

// getting the information
const types = (type) => {
  const options = {
    processing: {
      columns: tableProcessingColumns(),
      rows: processingRows,
    },
    storage: {
      columns: tableStorageColumns(),
      rows: storageRows,
    },
    creditsadded: {
      columns: tableCreditColumns(),
      rows: creditsRows,
    },
    archiveview: {
      columns: tableArchiveViewColumns(),
      rows: archiveViewRows,
    },
    default: {
      columns: defaultColumns(),
      rows: defaultRows,
    },
  };
  return options[type];
};

let debounceTimeout = null;

export const CreditUsageDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { detail, filters, loading } = useSelector((state) => state.creditUsage?.panels);
  const { config } = useContext(SidebarContext);
  const type = types(String(detail?.info?.credits_type || 'default').toLowerCase());
  const columns = useMemo(() => type?.columns, []);
  const component = config.header.find((item) => item.name === 'creditUsageDetail');
  const { actions } = component;

  const table = useTable(
    {
      columns,
      data: detail?.data || type?.rows,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(detail?.totalCount / filters.page_size),
      totalCount: detail?.totalCount || type?.rows?.length,
      pageLength: filters.page_size,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const exportData = () => {
    const credits = [...detail?.data];
    exportFromJSON({
      data: credits,
      fileName: `CartoVid_Credit_Usage_${detail?.info?.credits_type}_${moment(new Date()).format(
        'YYYY-MM-DD_HH:MM:SS'
      )}`,
      exportType: exportFromJSON.types.csv,
      delimiter: ',',
    });
  };

  const handleSearch = (e) => dispatch(setSearchDetail(e.target.value));

  useEffect(() => {
    if (!detail.info) {
      history.goBack();
    }
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      dispatch(
        getCreditDetail({
          type: detail?.info?.credits_type.toLowerCase(),
          date: moment(detail?.info?.created_date).format('YYYY-MM-DD'),
          granularity: 'montly',
          ...filters,
        })
      );
    }, 300);
  }, [filters]);

  useEffect(() => {
    dispatch(setSearchDetail());
  }, []);

  useEffect(() => {
    dispatch(setPageFilterDetail(pageIndex + 1));
  }, [pageIndex, pageSize]);

  const CreditContainer = () => (
    <>
      <TopBar
        searchValue={filters.search}
        handleSearch={handleSearch}
        filterButton={false}
        title="Credit Use Detail"
        credit
      />
      <GeneralTable table={table} credit />
      <TablePagination table={table} position="end" credit />
    </>
  );

  const EmptyCreditContainer = () => (
    <WidgetSplash image={SplashImage} alt="Credit Splash" title="There are currently no details." />
  );

  if (loading && !detail?.data?.length) {
    return <Loader loading={loading} height={250} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className="title">
          <div>
            <Button
              onClick={() => actions.handleClose()}
              style={{ marginRight: '10px' }}
              className={classes.headerButton}
              variant="contained"
              color="primary"
              disableElevation
            >
              <ArrowLeftIcon size={20} />
            </Button>
            <div className={classes.titleOne}>Administration</div>
            <span className={classes.titleBreadcumb}>
              <MoreThanIcon size={16} />
            </span>
            <div className={classes.titleSecond}>Credit Use</div>
            <span className={classes.titleBreadcumb}>
              <MoreThanIcon size={16} />
            </span>
            <div className={classes.titleSubHeading}>Credits Usage Detail</div>
          </div>
          <Button
            onClick={() => actions.handleClose()}
            className={classes.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
      </div>

      <Card noPadding>
        <div
          style={{ ...(!detail?.data?.length && { height: 'calc(100vh - 140px)' }) }}
          className={classes.generalContent}
        >
          <div className={classes.content}>
            <div className={`${classes.borderTop} ${classes.headerDetail}`}>
              <div className={classes.headerDetailText}>
                <div className={classes.titleOne}>Credit Use Details</div>
                <span className={classes.titleBreadcumb}>
                  <MoreThanIcon size={16} />
                </span>
                <div className={classes.titleSubHeading}>
                  {moment(detail?.info?.created_date || new Date()).format('MM/DD/YYYY')}
                </div>
                <span className={classes.titleBreadcumb}>
                  <MoreThanIcon size={16} />
                </span>
                <div className={classes.titleSubHeading}>{detail?.info?.credits_type || 'NaN'}</div>
              </div>

              <div className={classes.headerDetailButton}>
                <Button
                  onClick={() => exportData()}
                  disabled={!detail?.data?.length}
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                >
                  <SquareArrow />
                  <Typography sx={{ fontSize: 14, marginLeft: '7px' }}>Export</Typography>
                </Button>
              </div>
            </div>

            <div
              style={{ ...(!detail?.data?.length && { height: 'calc(100vh - 220px)' }) }}
              className={`${classes.borderBottom} ${classes.contentFooter}`}
            >
              {detail?.data?.length ? <CreditContainer /> : <EmptyCreditContainer />}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
