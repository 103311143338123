import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import LinkedFilesService from 'services/LinkedFilesService';
import { DeleteIcon } from 'components/Icons/DeleteIcon';
import { FilePageIcon } from 'components/Icons/FilePageIcon';

const UploadBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.grey[400],
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '126px',
  margin: '16px 0',
}));

const FileItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  '&:hover': {
    borderColor: theme.palette.grey[100],
    backgroundColor: theme.palette.action.hover,
  },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const validationSchema = Yup.object().shape({
  uuid: Yup.string().matches(/^[0-9a-fA-F-]{36}$/, 'Invalid UUID format'),
  files: Yup.array(),
});

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(1))} ${sizes[i]}`;
};

export const LinkedFilesModal = ({ open, onClose, projectId, onFileUploaded, isUpdate, fileToUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingDescription, setEditingDescription] = useState(null);
  const [shouldReset, setShouldReset] = useState(false);

  const handleClose = () => {
    setShouldReset(true);
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      files: [],
      uuid: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const uploadPromises = values.files.map(async (fileData) => {
          try {
            if (isUpdate && !fileToUpdate?.id) {
              throw new Error('File ID is required for update');
            }

            // step 1: get signed URL
            const uploadData = {
              name: fileData.file.name,
              description: fileData.description || '',
              fileType: fileData.file.type,
            };

            // get the response
            const response = isUpdate
              ? await LinkedFilesService.generateUpdateUrl(fileToUpdate.id, uploadData)
              : await LinkedFilesService.generateUploadUrl({ ...uploadData, projectId });

            const {
              data: { data },
            } = response;
            const { id, signedUrl } = data.files[0];

            // step 2: upload to GCS directly
            const uploadSuccess = await LinkedFilesService.uploadToSignedUrl(signedUrl, fileData.file);

            if (uploadSuccess) {
              // step 3: notify backend of completion
              if (isUpdate) {
                await LinkedFilesService.completeUpdate(fileToUpdate.id, {
                  files: [
                    { ext: fileData.file.name.split('.').pop(), id, description: fileToUpdate.description || '' },
                  ],
                });
              } else {
                await LinkedFilesService.completeUpload([
                  {
                    id,
                    ext: fileData.file.name.split('.').pop(),
                    description: fileData.description || '',
                  },
                ]);
              }

              // update progress in UI
              const newFiles = [...formik.values.files];
              const fileIndex = newFiles.findIndex((f) => f.file.name === fileData.file.name);
              if (fileIndex !== -1) {
                newFiles[fileIndex].progress = 100;
                newFiles[fileIndex].completed = true;
                formik.setFieldValue('files', newFiles);
              }
            }
          } catch (error) {
            console.error(`Error ${isUpdate ? 'updating' : 'uploading'} file ${fileData.file.name}:`, error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        onFileUploaded(); // refresh the file list
        handleClose();
      } catch (error) {
        console.error(`Error ${isUpdate ? 'updating' : 'uploading'} files:`, error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (shouldReset) {
      formik.resetForm();
      setEditingDescription(null);
      setShouldReset(false);
    }
  }, [shouldReset, formik]);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    formik.setFieldValue('files', [
      ...formik.values.files,
      ...droppedFiles.map((file) => ({
        file,
        progress: 0,
        completed: false,
        description: '',
      })),
    ]);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    formik.setFieldValue('files', [
      ...formik.values.files,
      ...selectedFiles.map((file) => ({
        file,
        progress: 0,
        completed: false,
        description: '',
      })),
    ]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...formik.values.files];
    newFiles.splice(index, 1);
    formik.setFieldValue('files', newFiles);
  };

  const handleDescriptionClick = (index) => {
    setEditingDescription(index);
  };

  const handleDescriptionChange = (index, description) => {
    const newFiles = [...formik.values.files];
    newFiles[index] = { ...newFiles[index], description };
    formik.setFieldValue('files', newFiles);
  };

  const handleDescriptionKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setEditingDescription(null);
    }
  };

  const handleDescriptionBlur = () => {
    setEditingDescription(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="linked-files-modal-title">
      {isLoading && <LoadingOverlay />}
      <DialogTitle sx={{ mt: 1 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" sx={{ color: 'white' }}>
            {isUpdate ? 'Update File' : 'Add Linked File'}
          </Typography>
        </Box>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ width: 480, height: 712, overflow: 'hidden auto' }}>
          <Typography gutterBottom sx={{ color: '#B1B1B1' }}>
            {isUpdate
              ? `Select the file you would like to replace ${fileToUpdate?.name || ''} with.`
              : 'Select the file(s) you would like to upload and link to the project.'}
          </Typography>
          <UploadBox
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById('file-input').click()}
          >
            <input
              id="file-input"
              type="file"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              multiple={!isUpdate}
              accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.zip,.rar"
            />
            <CloudUploadIcon sx={{ fontSize: 36, color: 'secondary', mb: 0.5 }} />
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <Typography color="secondary" sx={{ fontWeight: 'bolder' }}>
                {isUpdate ? 'Select file' : 'Click to upload'}
              </Typography>
              <Typography sx={{ color: '#B1B1B1' }}>or drag and drop</Typography>
            </div>
            <Typography variant="caption" sx={{ color: '#B1B1B1' }}>
              Supported formats: PDF, DOC, DOCX, XLS, XLSX, TXT, CSV, ZIP, RAR
            </Typography>
          </UploadBox>
          {formik.values.files.map((fileData, index) => (
            <FileItem key={fileData.file.name}>
              <FilePageIcon type={fileData.file.type} size={32} />
              <Box sx={{ ml: 1, flex: 1, overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography noWrap sx={{ color: 'white', flex: 1 }}>
                    {fileData.file.name}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#B1B1B1', whiteSpace: 'nowrap' }}>
                    {formatFileSize(fileData.file.size)}
                  </Typography>
                </Box>
                {editingDescription === index ? (
                  <TextField
                    fullWidth
                    autoFocus
                    size="small"
                    value={fileData.description || ''}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                    onKeyDown={(e) => handleDescriptionKeyDown(e, index)}
                    onBlur={handleDescriptionBlur}
                    placeholder="Enter a description"
                    sx={{
                      mt: 0.5,
                      '& .MuiInputBase-input': {
                        color: 'white',
                        fontSize: '16px',
                        padding: '6px 10px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                    }}
                  />
                ) : (
                  <DescriptionText variant="body2" onClick={() => handleDescriptionClick(index)} sx={{ mt: 0.5 }}>
                    {fileData.description || 'Click to add a description'}
                  </DescriptionText>
                )}
                <LinearProgress variant="determinate" value={fileData.progress} sx={{ mt: 1, maxWidth: '100%' }} />
              </Box>
              {fileData.completed ? (
                <CheckCircleIcon color="success" sx={{ ml: 1 }} />
              ) : (
                <IconButton size="small" onClick={() => handleRemoveFile(index)} sx={{ ml: 1 }}>
                  <DeleteIcon size={16} sx={{ color: 'white' }} />
                </IconButton>
              )}
            </FileItem>
          ))}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              gap: '16px',
              position: 'absolute',
              bottom: '0',
              width: '90%',
            }}
          >
            {!isUpdate && (
              <Box>
                <Typography variant="subtitle1" gutterBottom sx={{ color: 'white' }}>
                  Add Via UUID
                </Typography>
                <TextField
                  fullWidth
                  name="uuid"
                  placeholder="Paste UUID Number"
                  value={formik.values.uuid}
                  onChange={formik.handleChange}
                  error={formik.touched.uuid && Boolean(formik.errors.uuid)}
                  helperText={formik.touched.uuid && formik.errors.uuid}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                  }}
                />
              </Box>
            )}
            <DialogActions
              sx={{
                marginBottom: '24px',
              }}
            >
              <Button variant="contained" onClick={handleClose} sx={{ width: '50%', marginLeft: -1 }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isLoading || (formik.values.files.length === 0 && !formik.values.uuid)}
                sx={{ width: '50%', marginLeft: 2 }}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading ? (isUpdate ? 'Updating...' : 'Uploading...') : isUpdate ? 'Update' : 'Confirm'}
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
};

LinkedFilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  fileToUpdate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

LinkedFilesModal.defaultProps = {
  isUpdate: false,
  fileToUpdate: null,
};

export default LinkedFilesModal;
