import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: [],
  filters: {
    search: '',
    teamType: 'all',
    sortBy: 'name',
    sortOrder: 'asc',
    favorites: false,
    fileTypes: [],
    externalAccounts: [],
    tags: [],
    userMembership: [],
    activated: false,
  },
  selectedTeams: [],
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      // If any filter is set, mark filters as activated
      const hasActiveFilters = Object.entries(state.filters).some(([key, value]) => {
        if (key === 'activated') return false;
        if (Array.isArray(value)) return value.length > 0;
        if (key === 'teamType') return value !== 'all';
        if (typeof value === 'boolean') return value;
        return !!value;
      });
      state.filters.activated = hasActiveFilters;
    },
    setSelectedTeams: (state, action) => {
      state.selectedTeams = action.payload;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const { setTeams, setFilters, setSelectedTeams, clearFilters } = teamsSlice.actions;

export default teamsSlice.reducer;
