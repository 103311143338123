/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { CalendarModal } from 'components/CalendarModal';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import PropTypes from 'prop-types';

export const SearchPanelBody = ({
  loaded,
  filters,
  users,
  // eslint-disable-next-line no-unused-vars
  setFilterValue,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const [filterActions, setFiltersActions] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [localFilters, setLocalFilters] = useState({
    user_ids: [],
    active: null,
    date_range: null,
  });

  // clear sort
  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  // update filters
  const updateFilter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update radio buttons
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          active: null,
          sort_by: {},
          user_ids: [],
          date_range: null,
          activated: false,
        })
      );
    }
  };

  // apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  // create clear filters
  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters?.user_ids?.length > 0)
      auxFilters.push({ text: 'Clear By User', callBack: () => updateNormalFilters([], 'user_ids') });
    if (clearFilters.active !== null && clearFilters.active !== undefined)
      auxFilters.push({
        text: `Clear ${clearFilters.active ? 'Switched On' : 'Switched Off'}`,
        callBack: () => updateNormalFilters(null, 'active'),
      });
    if (clearFilters.sort_by?.name) auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    if (clearFilters.sort_by?.createdAt)
      auxFilters.push({ text: 'Sort By Created At', callBack: () => clearSort('createdAt') });
    if (clearFilters.sort_by?.org) auxFilters.push({ text: 'Sort By Org', callBack: () => clearSort('org') });
    if (clearFilters.date_range != null && clearFilters.date_range)
      auxFilters.push({ text: 'Clear Date Range', callback: () => updateNormalFilters(null, 'date_range') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
      title="Filters: Map/GIS Services"
      subTitle="Apply filters to table data."
      filters
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <Button onClick={() => setOpenCalendar(true)} className="marginTop" color="secondary" variant="contained">
          By Date Range
        </Button>
        <FormControl>
          <FormLabel className="marginTop">Switched On/Off</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.active}
            onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'active')}
            options={[
              { label: 'All', value: null },
              { label: 'On', value: true },
              { label: 'Off', value: false },
            ]}
            view="horizontal"
          />
        </FormControl>

        <FormControl>
          <FormLabel className="marginTop">Type</FormLabel>
          <FormRadioGroup
            name="open"
            value={localFilters.isOpen}
            onChecked={(checkedVal) => updateNormalFilters(checkedVal, 'isOpen')}
            options={[
              { label: 'All', value: null },
              { label: 'Service', value: true },
              { label: 'Map', value: false },
            ]}
            view="horizontal"
          />
        </FormControl>

        <SearchList
          title="Added By"
          filter="user_ids"
          localFilters={localFilters}
          onChangeSelect={updateFilter}
          fullList={users}
          loaded={loaded}
        />
      </div>

      {openCalendar && (
        <CalendarModal
          rangeDate={localFilters.date_range}
          handleClose={() => setOpenCalendar(false)}
          handleConfirm={(rangeDate) => {
            if (rangeDate.length && rangeDate[0].startDate != null && rangeDate[0].endDate != null) {
              updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
            }
            setOpenCalendar(false);
          }}
        />
      )}
    </SidebarPanel>
  );
};

SearchPanelBody.propTypes = {
  loaded: PropTypes.bool,
  filters: PropTypes.object,
  users: PropTypes.array,
  setFilterValue: PropTypes.func,
  setFilters: PropTypes.func,
};

SearchPanelBody.defaultProps = {
  loaded: false,
  filters: null,
  users: [],
  setFilterValue: () => {},
  setFilters: () => {},
};
