import React from 'react';
import { useSelector } from 'react-redux';
import { setFilters } from 'slices/linkedFilesReducer';
import { LinkedFilesSearchPanel } from './LinkedFilesSearchPanel';

export const SearchPanel = () => {
  const filters = useSelector((state) => state.linkedFiles.filters);

  return <LinkedFilesSearchPanel filters={filters} setFilters={setFilters} />;
};
