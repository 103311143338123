import PropTypes from 'prop-types';
import React from 'react';

import { Svg } from './Svg';

export const BrochureMapServices = ({ size }) => (
  <div style={{ paddingTop: '10px', paddingLeft: '4px', paddingBottom: '10px' }}>
    <Svg
      viewBox="-127.57 -127.57 745.81 745.81"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#039855"
      strokeWidth="15.211"
      fill="#039855"
      transform="rotate(180)"
      size={size}
      version="1.1"
      xmlSpace="preserve"
    >
      <rect width="745.81" height="745.81" x="-127.57" y="-127.57" fill="#94f5d5" strokeWidth="0" rx="372.905" />
      <path d="M485.867 12.372c-2.987-1.92-6.827-2.24-10.133-.853l-141.76 62.293-157.547-62.4c-1.493-.533-3.2-.747-4.8-.533-.32 0-.533-.213-.853-.213-2.453 0-4.8.96-6.72 2.56L6.72 75.412C2.667 77.012 0 80.959 0 85.332v384c0 5.867 4.8 10.667 10.667 10.667 1.387 0 2.667-.213 3.947-.747l157.867-62.4 157.867 62.4c2.667 1.067 5.653.96 8.213-.107l145.707-64c3.84-1.707 6.4-5.547 6.4-9.813v-384c-.001-3.626-1.815-6.933-4.801-8.96zM160 398.826L21.333 453.652V92.586L160 37.759v361.067zm160 53.44l-138.667-54.933V36.266L320 91.092v361.174zm149.333-53.867l-128 56.213V93.866l128-56.213v360.746z" />
    </Svg>
  </div>
);

BrochureMapServices.propTypes = {
  size: PropTypes.number,
};

BrochureMapServices.defaultProps = {
  size: 24,
};
