import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PencilIcon, DeleteIcon } from 'components/Icons';
import { mapGisAction } from 'pages/Admin/MapGis/helpers';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

export const MapGisActions = ({ row, handleConfirm }) => {
  const classes = useStyles();

  // go to actions
  const handleActions = (action) => {
    handleConfirm(action, row?.original);
  };

  return (
    <div className={classes.actions}>
      <Tooltip title="Edit">
        <IconButton onClick={() => handleActions(mapGisAction.EDIT)}>
          <PencilIcon size={15} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete">
        <IconButton onClick={() => handleActions(mapGisAction.DELETE)}>
          <DeleteIcon size={15} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

MapGisActions.propTypes = {
  row: PropTypes.object,
};

MapGisActions.defaultProps = {
  row: null,
};
