import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatDateRange } from './helpers';
import { mapGisFilterButtonsValues } from 'pages/Admin/MapGis/helpers';
import MapGisService from 'services/MapGisService';

export const getMapGisServicesAll = createAsyncThunk(
  'adminMapGis/getMapGisServicesAll',
  async ({ filters, exclude }) => {
    const newFilters = {
      strict: true,
      ...filters,
    };
    ['date_range', 'activated'].forEach((attr) => delete newFilters[attr]);

    if (filters?.user_ids && filters?.user_ids.length) {
      newFilters.user_ids = JSON.stringify(filters?.user_ids);
    }
    if (filters?.date_range != null) {
      const dates = formatDateRange(filters?.date_range);
      newFilters.start_date = dates?.createdAt?.gte;
      newFilters.end_date = dates?.createdAt?.lte;
    }

    try {
      const response = await MapGisService.getMapGisServicesAll(newFilters);
      return {
        exclude,
        response: response.data,
      };
    } catch {
      throw new Error('Error during request for map/GIS services-all...');
    }
  }
);

export const getMapGisRows = createAsyncThunk('adminMapGis/getMapGisRows', async (filters) => {
  const newFilters = {
    strict: true,
    ...filters,
  };

  delete newFilters.date_range;
  delete newFilters.activated;
  delete newFilters.acctId;
  delete newFilters.organizationId;

  try {
    const response = await MapGisService.getMapGisServices(newFilters);
    return response.data;
  } catch (e) {
    throw new Error('Error during request for map/GIS services...');
  }
});

export const getMapGisRow = createAsyncThunk(
  'adminMapGis/getMapGisRow',
  async (id) => {
    try {
      const [mapGisService] = await Promise.all([await MapGisService.getMapGisService(id)]);
      return { mapGisService };
    } catch (error) {
      throw new Error('Error during request for single Map/GIS Service...');
    }
  },
  {
    condition: (id, { getState }) => getState().adminMapGis.panels.id !== id,
  }
);

export const preloadFilterDatasets = createAsyncThunk(
  'adminMapGis/preloadFilterDatasets',
  async () => {
    try {
      const response = await MapGisService.getMapGisServicesAll({ page_size: 500 });
      return response.data.data;
    } catch (err) {
      throw new Error('error loading filter data');
    }
  },
  {
    condition: (_, { getState }) => getState().adminMapGis.panels.search.loaded === false,
  }
);

export const preloadFilterButtons = createAsyncThunk('adminMapGis/preloadFilterButtons', async ({ exclude }) => {
  try {
    const response = await MapGisService.getMapGisServicesAll({ page_size: 500 });
    return {
      exclude,
      data: response.data.data,
    };
  } catch {
    throw new Error('Error during request for preload filter buttons...');
  }
});

const initialState = {
  error: null,
  loading: false,
  data: [],
  totalCount: 0,
  panels: {
    projId: null,
    id: null,
    loading: false,
    projMapGisDetails: {},
    search: {
      loaded: false,
      addedBy: [],
      switched: [],
      type: [],
      active: null,
    },
    buttons: {
      items: [],
      loaded: false,
    },
    gisServices: false,
  },
  filters: {
    page: 1,
    page_size: 10,
    sort_by: {},
    search: '',
    active: true,
    level: '',
    user_ids: [],
    date_range: null,
  },
};

const mapGisSlice = createSlice({
  name: 'adminMapGis',
  initialState,
  reducers: {
    resetAdminMapGis: () => initialState,
    setPageFilter(state, action) {
      state.filters.page = action.payload;
    },
    setSearch(state, action) {
      state.filters.search = action.payload;
    },
    setSortBy(state, { payload }) {
      const { label, value } = payload;

      if (value === '' || value === null) {
        delete state.filters.sort_by[label];
      } else {
        state.filters.sort_by[label] = value;
      }
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setFilterValue(state, { payload }) {
      const { param, value } = payload;

      if ((value === '' || value === null) && param !== 'date_range') {
        delete state.filters[param];
        return;
      }

      state.filters[param] = payload.value;
    },
    setFilters(state, action) {
      const filters = { ...state.filters, ...action.payload, page: 1 };
      if (!filters?.participantLevel) delete filters?.participantLevel;
      state.filters = filters;
    },
    setGisServices(state, action) {
      state.panels.gisServices = action.payload;
    },
    setSortedData(state, action) {
      state.data = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapGisServicesAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMapGisServicesAll.fulfilled, (state, { payload }) => {
      // setting the state
      let data = [...payload.response.data];
      let totalCount = 0;
      if (payload.exclude) {
        totalCount = data.filter((item) => item.levelDesc === payload.exclude).length;
        data = data.filter((item) => item.levelDesc !== payload.exclude);
      }
      state.data = data;
      state.totalCount = payload.response.meta.totalCount - totalCount;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getMapGisServicesAll.rejected, (state) => {
      state.data = [];
      state.totalCount = 0;
      state.error = 'There was an error during the request.';
      state.loading = false;
    });
    // preload filters
    builder.addCase(preloadFilterDatasets.pending, (state) => {
      state.panels.search.loaded = false;
    });
    builder.addCase(preloadFilterDatasets.fulfilled, (state, action) => {
      state.panels.search.loaded = true;
      const addedBy = [];
      action.payload.forEach((item) => {
        const { user } = item;
        const exist = addedBy.find((attr) => attr.value === user.id);
        if (!exist) {
          addedBy.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          });
        }
      });
      state.panels.search.addedBy = addedBy;
    });
    builder.addCase(preloadFilterDatasets.rejected, (state) => {
      state.panels.search.loaded = false;
      state.panels.search.addedBy = [];
    });
    // preload filter buttons
    builder.addCase(preloadFilterButtons.pending, (state) => {
      state.panels.buttons.loaded = false;
    });
    builder.addCase(preloadFilterButtons.fulfilled, (state, action) => {
      state.panels.buttons.loaded = true;
      // setting the filters buttons
      let buttons = [{ text: 'All', value: '' }];
      [...new Set(action.payload.data.map((item) => item.levelDesc))].forEach((item) => {
        buttons.push({
          text: mapGisFilterButtonsValues[item],
          value: item,
        });
      });
      if (action.payload.exclude) {
        buttons = buttons.filter((item) => item.value !== action.payload.exclude);
      }
      state.panels.buttons.items = buttons;
    });
    builder.addCase(preloadFilterButtons.rejected, (state) => {
      state.panels.buttons.loaded = false;
      state.panels.buttons.items = [];
    });
  },
});

export const {
  setSearch,
  setSortBy,
  setPageFilter,
  setFilterValue,
  setLoading,
  setFilters,
  setGisServices,
  setSortedData,
  resetAdminMapGis,
} = mapGisSlice.actions;

export default mapGisSlice.reducer;
