import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  DialogContent,
  InputLabel,
} from '@mui/material';
import { FeatureIcon, CloseIcon } from 'components/Icons';
import { Kml } from './Kml';
import { useStyles } from './styles';
import { mapGisAction } from 'pages/Admin/MapGis/helpers';
import PropTypes from 'prop-types';

export const ModalLink = ({ open, data, section, handleOpen, onClose }) => {
  const [panel, setPanel] = useState(null);
  const [title, setTitle] = useState('');
  const classes = useStyles();
  const options = [
    { value: 'kml', label: 'Keyhole Markup Language (KML)', disabled: false },
    { value: 'hfc', label: 'Hosted Feature Class', disabled: true },
    { value: 'sf', label: 'Shaped File', disabled: true },
  ];
  const titles = {
    kml: 'KML',
    default: 'Link to Other Maps or Services',
  };

  const handleClose = () => {
    handleOpen(false);
  };

  const handleLocalOpen = () => {
    handleOpen();
    onClose();
  };

  const handleOption = (option) => {
    setTitle(titles[option.value]);
    setPanel(option.value);
  };

  useEffect(() => {
    if (data?.action === mapGisAction.EDIT) {
      let type = data?.data?.mapType;
      if (type === 'kmz') {
        type = 'kml';
      }
      handleOption({ value: type });
    } else {
      setTitle(titles.default);
      setPanel(null);
    }
  }, [data]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FeatureIcon size={50} />
          <Button
            onClick={handleClose}
            className={classes.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
        <Typography style={{ fontWeight: 500, marginTop: '15px' }} variant="h4">
          {title}
        </Typography>
      </DialogTitle>
      {/* display the default form */}
      {panel === null && (
        <>
          <DialogContent>
            <Grid container>
              <Grid xs={12} sm={12} lg={12} xl={12} item>
                <InputLabel>Map or Service</InputLabel>
                <Autocomplete
                  onChange={(event, value) => handleOption(value)}
                  options={Object.values(options)}
                  getOptionDisabled={(option) =>
                    !!options.find((item) => item.value === option.value && option.disabled)
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Select type" />}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>

          <div className={classes.actions}>
            <div className={classes.actionsContent} style={{ marginRight: 5 }}>
              <Button onClick={handleClose} color="primary" variant="contained" fullWidth disableElevation>
                Cancel
              </Button>
            </div>

            <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
              <Button color="secondary" variant="contained" fullWidth disableElevation>
                Next
              </Button>
            </div>
          </div>
        </>
      )}
      {/* open the kml content with the necessary form */}
      {['kml'].includes(panel) && (
        <Kml
          data={data}
          section={section}
          setPanel={(value) => {
            setPanel(value);
            setTitle(titles.default);
          }}
          handleOpen={handleLocalOpen}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

ModalLink.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  section: PropTypes.string,
  handleOpen: PropTypes.func,
  onClose: PropTypes.func,
};

ModalLink.defaultProps = {
  open: false,
  data: {},
  section: '',
  handleOpen: () => {},
  onClose: () => {},
};
