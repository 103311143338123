import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  container: {
    background: 'transparent!important',
    color: 'white!important',
    fontWeight: 400,
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0!important',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiFormControlLabel-root': {
      width: 'auto!important',
      margin: 0,
    },
    '& .icons': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fontSize: '22px',
        color: 'white',
      },
    },
  },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '40vh',
    borderRadius: 8,
    overflow: 'hidden',
    '& .loader-component': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      zIndex: 101,
      background: theme.palette.modalBackground,
    },
  },
  map: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  mapBtnContainer: {
    height: 'auto',
    widt: '100%',
    position: 'relative',
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'inter',
    color: theme.palette.primary[300],
  },
});
