import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  buttonContent: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem!important',
  },
  optionsContent: {
    position: 'absolute',
    background: 'white',
    color: 'black',
    textAlign: 'left',
    width: '100%-16px',
    top: '100%',
    left: '8px',
    right: '8px',
  },
  textOption: {
    cursor: 'pointer',
    display: 'flex',
    borderTop: `1px solid ${theme.palette.primary[1300]}`,
    width: '100%',
    padding: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  buttonStyle: {
    position: 'relative!important',
    width: '100%',
    // backgroundColor: 'white!important',
    '&:hover': {
      backgroundColor: `${theme.palette.primary[200]}`,
    },
    '& span': {
      display: 'inherit',
    },
    border: `1px solid ${theme.palette.primary[900]}!important`,
    height: '41px',
    padding: `${0}!important`,
  },
  styledImage: {
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    marginRight: '6px',
    marginLeft: '6px',
  },
  styledSpan: {
    width: '100%',
    height: '20px',
    lineHeight: '20px',
    display: 'inline-flex',
    marginLeft: '6px',
  },
  textButton: {
    fontSize: '15px!important',
    fontWeight: `${600}!important`,
    marginRight: '8px!important',
    marginLeft: '8px!important',
    color: `${theme.palette.primary[400]}`,
  },
});
