import React from 'react';
import { useSelector } from 'react-redux';
import { setFilters } from 'slices/projectReducer';
import { ProjectsSearchPanel } from './ProjectsSearchPanel';

export const SearchPanel = () => {
  const filters = useSelector((state) => state.projects.filters);

  return <ProjectsSearchPanel filters={filters} setFilters={setFilters} />;
};
