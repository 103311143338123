import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  buttonActions: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
  cardsContainer: {
    flex: 1,
    marginTop: `${theme.spacing(0)}!important`,
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: 'auto',
    '& .MuiPaper-root': {
      height: '100%',
    },
    backgroundColor: theme.palette.primary[1300],
  },
});
