import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const FeatureIcon = ({ size }) => (
  <Svg size={size} viewBox="0 0 56 56" fill="none">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" strokeWidth="8" />
    <path
      d="M24 34L17 38V22L24 18M24 34L32 38M24 34V18M32 38L39 34V18L32 22M32 38V22M32 22L24 18"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

FeatureIcon.propTypes = {
  size: PropTypes.number,
};

FeatureIcon.defaultProps = {
  size: 56,
};
