import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
import { FilterIcon, SearchIcon, AlertTriangleIcon } from 'components/Icons';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { setFilters } from 'slices/linkedFilesReducer';
import { SearchPanel } from './SearchPanel/SearchPanel';
import { useStyles } from '../styles';
import LinkedFilesService from 'services/LinkedFilesService';
import { LinkToContentModal } from './LinkToContentModal';

const bulkActions = [
  {
    label: 'Attach to Content',
    value: 'attach',
  },
  {
    label: 'Delete from Project',
    value: 'delete',
  },
];

export const LinkedFilesFilters = ({ detail, onFileUploaded, selectedRows = [], projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.linkedFiles);
  const [selectedAction, setSelectedAction] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showLinkToContent, setShowLinkToContent] = useState(false);

  const { displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
        background: true,
      },
    },
  });

  const handleOpenFilters = () => {
    setFullWidth(false);
    displayPanel('search');
  };

  const filterOptions = (value) => {
    dispatch(
      setFilters({
        search: value,
      })
    );
  };

  const handleActionChange = (event) => {
    switch (event.target.value) {
      case 'attach':
        setShowLinkToContent(true);
        break;
      case 'delete':
        setShowDeleteConfirm(true);
        break;
      default:
        break;
    }
    setSelectedAction(event.target.value);
  };

  const handleBulkDelete = async () => {
    try {
      await LinkedFilesService.bulkRemove({ ids: selectedRows });
      setShowDeleteConfirm(false);
      setSelectedAction('');
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      console.error('Error deleting files:', error);
    }
  };

  return (
    <>
      {detail ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
            fontSize: '16px',
          }}
        >
          <FormControl style={{ width: '400px' }}>
            <Select
              value={selectedAction}
              onChange={handleActionChange}
              displayEmpty
              disabled={selectedRows.length === 0}
              style={{ width: '320px', height: '44px', fontSize: '16px' }}
            >
              <MenuItem value="" disabled>
                Bulk Actions
              </MenuItem>
              {bulkActions.map((action, index) => (
                <MenuItem
                  key={action.value}
                  value={action.value}
                  style={{
                    fontSize: '16px',
                    padding: '10px 14px',
                    borderTop: index === bulkActions.length - 1 ? '1px solid gray' : 'none',
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: 'flex', alignItems: 'center', width: '500px' }}>
            <TextField
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: <SearchIcon size={20} />,
              }}
              value={filters.search}
              onChange={(e) => filterOptions(e.target.value)}
              style={{ width: '400px' }}
            />
            <Button
              size="medium"
              color="primary"
              variant="contained"
              className={`${classes.filterButton} ${filters.activated && classes.activatedFilter}`}
              onClick={() => handleOpenFilters()}
              disableElevation
            >
              <FilterIcon size={15} /> Filters {filters.activated && 'On'}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Typography variant="h4">Linked Files</Typography>
          <Typography>Manage and organize files linked to this project.</Typography>
        </>
      )}

      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <Box sx={{ mt: 1, ml: 1 }}>
          <AlertTriangleIcon size={40} />
        </Box>
        <DialogTitle>
          <Typography variant="h4">Are you sure?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom sx={{ fontWeight: 'normal', color: '#B1B1B1' }}>
            This action cannot be undone. Are you sure you want to remove {selectedRows.length}{' '}
            {selectedRows.length === 1 ? 'file' : 'files'}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeleteConfirm(false);
              setSelectedAction('');
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleBulkDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <LinkToContentModal
        open={showLinkToContent}
        onClose={() => setShowLinkToContent(false)}
        fileId={selectedRows}
        projectId={projectId}
      />
    </>
  );
};

LinkedFilesFilters.propTypes = {
  detail: PropTypes.bool,
  onFileUploaded: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  projectId: PropTypes.number,
};

LinkedFilesFilters.defaultProps = {
  detail: false,
  selectedRows: [],
  projectId: 0,
};
