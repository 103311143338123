import ApiService from './ApiService';

export default class LinkedFilesService {
  static getLinkedFiles = (filters) => ApiService.get(`/linked-files`, filters);

  static generateUploadUrl = async (fileData) => ApiService.post(`/linked-files/generateUploadSignURL`, fileData);

  static generateUpdateUrl = async (fileId, fileData) =>
    ApiService.put(`/linked-files/${fileId}/generateUpdateSignURL`, fileData);

  static uploadToSignedUrl = async (signedUrl, file) => {
    // Direct upload to GCS using the signed URL
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    return response.ok;
  };

  static completeUpload = async (files) => ApiService.post(`/linked-files/completedUploadSignURL`, { files });

  static completeUpdate = async (fileId, data) =>
    ApiService.put(`/linked-files/${fileId}/completedUpdateSignURL`, data);

  static getDownloadUrl = async (fileId) => ApiService.get(`/linked-files/${fileId}/download`);

  static deleteLinkedFile = async (fileId) => ApiService.delete(`/linked-files/${fileId}`);

  static getLinkedFile = async (fileId) => ApiService.get(`/linked-files/${fileId}`);

  static updateLinkedFile = async (fileId, data) => ApiService.patch(`/linked-files/${fileId}`, data);

  static bulkRemove = async (data) => ApiService.post(`/linked-files/bulk-delete`, data);

  static attachToContent = async (data) => ApiService.post(`/linked-files/link-to-content`, data);

  static attachToContentBulk = async (data) => ApiService.post(`/linked-files/bulk-link-to-content`, data);
}
