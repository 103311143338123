import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OpenContext } from '../Nav/NavContext';
import { SuperAdminIcon } from '../Icons';
import { theme } from 'theme';

const useStyles = makeStyles({
  item: (props) => ({
    listStyleType: 'none',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: props.active ? '' : theme.palette.primary.light,
    },
    backgroundColor: props.active ? theme.palette.secondary.main : '',
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  itemLink: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '17px',
    fontWeight: '700',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    padding: 0,
  },
  styledIconWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    textAlign: 'center',
    color: 'white',
  },
});

export const SuperAdminButton = ({ active, onClick }) => {
  const classes = useStyles({ active });
  const { open } = useContext(OpenContext);

  return (
    <li className={classes.item}>
      <Tooltip title="Super Admin" arrow placement="right">
        <button type="button" className={classes.itemLink} onClick={onClick}>
          <span className={classes.styledIconWrap}>
            <SuperAdminIcon size={20} />
          </span>
          {open && 'Super Admin'}
        </button>
      </Tooltip>
    </li>
  );
};

SuperAdminButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SuperAdminButton.defaultProps = {
  active: false,
};
