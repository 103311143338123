import React, { useContext, useState } from 'react';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { CloseIcon, CirclePlusIcon } from 'components/Icons';
import { MoreThanIcon } from 'components/Icons/MoreThanIcon';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { useStyles } from './styles';
import { ORGANIZATIONS_TABS } from './lib';
import PropTypes from 'prop-types';
import { ModalAddUsers } from './Members/ModalAddUsers';
import { ModalAddTeams } from './Teams/ModalAddTeams';
import { ModalAddProjects } from './Projects/ModalAddProjects';

export const OrgDashboardHeaderDetail = ({
  data,
  tab,
  setTabValue,
  onMembersUpdate,
  onTeamsUpdate,
  onProjectsUpdate,
}) => {
  const { config } = useContext(SidebarDetailContext);
  const component = config?.header.find((item) => item.name === 'dashboard');
  const { actions } = component;
  const classes = useStyles();
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
  const [isTeamsModalOpen, setIsTeamsModalOpen] = useState(false);
  const [isProjectsModalOpen, setIsProjectsModalOpen] = useState(false);

  const handleOpenMembersModal = () => setIsMembersModalOpen(true);
  const handleOpenTeamsModal = () => setIsTeamsModalOpen(true);
  const handleOpenProjectsModal = () => setIsProjectsModalOpen(true);

  const handleCloseModal = () => {
    setIsMembersModalOpen(false);
    // after modal closes successfully, trigger members refetch
    if (onMembersUpdate) {
      onMembersUpdate();
    }
  };

  const handleCloseTeamsModal = () => {
    setIsTeamsModalOpen(false);
    // after modal closes successfully, trigger teams refetch
    if (onTeamsUpdate) {
      onTeamsUpdate();
    }
  };

  const handleCloseProjectsModal = () => {
    setIsProjectsModalOpen(false);
    // after modal closes successfully, trigger projects refetch
    if (onProjectsUpdate) {
      onProjectsUpdate();
    }
  };

  const AddButton = ({ openModalFunction }) => (
    <Button
      onClick={openModalFunction}
      style={{
        height: '36px',
        backgroundColor: '#FF9100',
        color: '#000',
        marginBottom: '6px',
      }}
    >
      <CirclePlusIcon size={15} />
      <span style={{ paddingLeft: '10px' }}>Add New</span>
    </Button>
  );

  return (
    <div className={classes.headerDetail}>
      <div className={classes.header}>
        <div className="title">
          <div>
            <button onClick={() => actions.handleClose()} type="button">
              <Typography fontSize={18} lineHeight="28px" fontWeight="500">
                Organization
              </Typography>
            </button>
            <Typography fontSize={14} lineHeight="20px" fontWeight="500">
              <span style={{ margin: '0 10px', paddingRight: '3px', color: '#B1B1B1' }}>
                <MoreThanIcon size={16} />
              </span>
              {data?.name}
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            {tab === ORGANIZATIONS_TABS.MEMBERS && <AddButton openModalFunction={handleOpenMembersModal} />}
            {tab === ORGANIZATIONS_TABS.TEAMS && <AddButton openModalFunction={handleOpenTeamsModal} />}
            {tab === ORGANIZATIONS_TABS.PROJECTS && <AddButton openModalFunction={handleOpenProjectsModal} />}
            <Button
              onClick={() => actions.handleClose()}
              className={classes.headerButton}
              color="primary"
              variant="contained"
              disableElevation
            >
              <CloseIcon size={15} />
            </Button>
          </div>
        </div>
        <Tabs className={classes.headerTabs} value={tab}>
          <Tab label="Dashboard" onClick={() => setTabValue(ORGANIZATIONS_TABS.DASHBOARD)} />
          <Tab label="Admins" onClick={() => setTabValue(ORGANIZATIONS_TABS.ADMINS)} />
          <Tab label="Members" onClick={() => setTabValue(ORGANIZATIONS_TABS.MEMBERS)} />
          <Tab label="Teams" onClick={() => setTabValue(ORGANIZATIONS_TABS.TEAMS)} />
          <Tab label="Projects" onClick={() => setTabValue(ORGANIZATIONS_TABS.PROJECTS)} />
          <Tab label="Content" onClick={() => setTabValue(ORGANIZATIONS_TABS.CONTENT)} />
          <Tab label="GIS/Maps" onClick={() => setTabValue(ORGANIZATIONS_TABS.MAPS)} />
          <Tab label="Inspections" onClick={() => setTabValue(ORGANIZATIONS_TABS.INSPECTIONS)} />
          <Tab label="Pending Deletion" onClick={() => setTabValue(ORGANIZATIONS_TABS.PENDING_DELETION)} />
          <Tab label="Action Required" onClick={() => setTabValue(ORGANIZATIONS_TABS.ACTION_REQUIRED)} />
          <Tab label="Settings" onClick={() => setTabValue(ORGANIZATIONS_TABS.SETTINGS)} />
        </Tabs>
      </div>
      <ModalAddUsers
        isOpen={isMembersModalOpen}
        onCancel={handleCloseModal}
        onConfirm={handleCloseModal}
        orgName={data?.name}
        orgId={data?.id}
      />
      <ModalAddTeams
        isOpen={isTeamsModalOpen}
        onCancel={handleCloseTeamsModal}
        onConfirm={handleCloseTeamsModal}
        orgName={data?.name}
        orgId={data?.id}
      />
      <ModalAddProjects
        isOpen={isProjectsModalOpen}
        onCancel={handleCloseProjectsModal}
        onConfirm={handleCloseProjectsModal}
        orgName={data?.name}
        orgId={data?.id}
      />
    </div>
  );
};

OrgDashboardHeaderDetail.propTypes = {
  data: PropTypes.object,
  tab: PropTypes.number,
  setTabValue: PropTypes.func,
  onMembersUpdate: PropTypes.func,
};

OrgDashboardHeaderDetail.defaultProps = {
  data: {},
  tab: 0,
  setTabValue: () => {},
  onMembersUpdate: () => {},
};
