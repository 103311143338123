import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
} from '@mui/material';
import { FormRadioGroup } from 'components/FormRadioGroup';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import { CalendarModal } from 'components/CalendarModal';
import { MapExtentModal } from 'pages/Profile/SingleProject/SearchPanel/MapExtentModal/MapExtentModal';
import SearchIcon from '@mui/icons-material/Search';

export const LinkedFilesSearchPanel = ({ filters, setFilters }) => {
  const [filterActions, setFiltersActions] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [modalMapOpen, setModalMapOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {
      name: filters.sort_by?.name || '',
    },
    fileType: filters.fileType || [],
    uploadedBy: filters.uploadedBy || [],
    linkedTo: filters.linkedTo || [],
    getExtent: filters.getExtent || false,
    byDateRange: filters.byDateRange || {
      from: null,
      to: null,
    },
  });

  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  const handleClearFilters = () => {
    setLocalFilters({
      sort_by: { name: '' },
      fileType: [],
      uploadedBy: [],
      linkedTo: [],
      getExtent: false,
      byDateRange: {
        from: null,
        to: null,
      },
    });
    setFilters({ activated: false });
  };

  const clearExtent = () => {
    const auxFilters = { ...localFilters };
    auxFilters.getExtent = false;
    setLocalFilters({ ...auxFilters });
  };

  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setFilters({
      ...auxFilters,
      activated: filterActions.length > 0,
    });
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) {
      auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    }
    if (clearFilters.fileType?.length > 0) {
      auxFilters.push({ text: 'Clear File Type', callBack: () => updateNormalFilters([], 'fileType') });
    }
    if (clearFilters.uploadedBy?.length > 0) {
      auxFilters.push({ text: 'Clear Uploaded By', callBack: () => updateNormalFilters([], 'uploadedBy') });
    }
    if (clearFilters.linkedTo?.length > 0) {
      auxFilters.push({ text: 'Clear Linked To', callBack: () => updateNormalFilters([], 'linkedTo') });
    }
    if (clearFilters.getExtent) {
      auxFilters.push({ text: 'Clear Map Extent', callBack: () => clearExtent() });
    }
    if (clearFilters.byDateRange.from || clearFilters.byDateRange.to) {
      auxFilters.push({
        text: 'Clear Date Range',
        callBack: () => updateNormalFilters({ from: null, to: null }, 'byDateRange'),
      });
    }
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  const handleFileTypeChange = (type, checked) => {
    const newFileTypes = checked
      ? [...(localFilters.fileType || []), type]
      : localFilters.fileType.filter((t) => t !== type);
    updateNormalFilters(newFileTypes, 'fileType');
  };

  const setApplyExtentCoords = () => {
    updateNormalFilters(true, 'getExtent');
    setModalMapOpen(false);
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  return (
    <SidebarPanel
      title="Filters Linked Files"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />

        <FormControl>
          <FormLabel>Sort</FormLabel>
          <FormRadioGroup
            name="alphabetical"
            value={localFilters.sort_by.name}
            onChecked={(checkedVal) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = checkedVal;
              updateNormalFilters(sortBy, 'sort_by');
            }}
            options={[
              { label: 'A - Z', value: '+name', disabled: false },
              { label: 'Z - A', value: '-name', disabled: false },
              { label: 'None', value: '', disabled: false },
            ]}
            view="horizontal"
          />
        </FormControl>

        <Stack spacing={2} direction="row" className="marginTop">
          <Button
            color={localFilters.getExtent ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => setModalMapOpen(true)}
          >
            By Map Extent
          </Button>
          <Button
            color={localFilters.byDateRange.from || localFilters.byDateRange.to ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => setOpenCalendar(true)}
          >
            By Date Range
          </Button>
        </Stack>

        <FormControl className="marginTop">
          <FormLabel>File Type</FormLabel>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localFilters.fileType?.includes('VIDEO')}
                  onChange={(e) => handleFileTypeChange('VIDEO', e.target.checked)}
                />
              }
              label="Video"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localFilters.fileType?.includes('IMAGE')}
                  onChange={(e) => handleFileTypeChange('IMAGE', e.target.checked)}
                />
              }
              label="Image"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={localFilters.fileType?.includes('LIDAR')}
                  onChange={(e) => handleFileTypeChange('LIDAR', e.target.checked)}
                />
              }
              label="LiDAR"
            />
          </div>
        </FormControl>

        <FormControl className="marginTop" fullWidth>
          <FormLabel>Uploaded By</FormLabel>
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div />
        </FormControl>

        <FormControl className="marginTop" fullWidth>
          <FormLabel>Linked To</FormLabel>
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div>
            <FormControlLabel control={<Checkbox />} label="Content Name" />
            <FormControlLabel control={<Checkbox />} label="Project" />
          </div>
        </FormControl>

        {openCalendar && (
          <CalendarModal
            handleClose={() => setOpenCalendar(false)}
            handleApply={(dateRange) => {
              updateNormalFilters(dateRange, 'byDateRange');
              setOpenCalendar(false);
            }}
            dateRange={localFilters.byDateRange}
          />
        )}

        {modalMapOpen && (
          <MapExtentModal handleClose={() => setModalMapOpen(false)} setApplyExtentCoords={setApplyExtentCoords} />
        )}
      </div>
    </SidebarPanel>
  );
};
