import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Card } from 'components/Card';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { setSelectedMembers } from 'slices/membersReducer';
import { MembersFilters } from './MembersFilters';
import OrganizationService from 'services/OrganizationService';
import PropTypes from 'prop-types';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { MemberDetailPanel } from './MemberDetailPanel';
import { AlertTriangleIcon } from 'components/Icons/AlertTriangleIcon';
import { Avatar } from 'components/Avatar';
import { useGlobalStyles } from 'styles';
import { getInitials } from 'lib/generalFunctions';
import { createPortal } from 'react-dom';
import { Permissions as PermsSlide } from '../../../Admins/Permissions/Permissions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, fetchUsers } from 'slices/adminAdminsReducer';
import { DialogAddUser, typeInviteData } from 'components/DialogAddUser/DialogAddUser';

export const Members = ({ org, onInit }) => {
  const dispatch = useDispatch();
  const { loading, filters } = useSelector((state) => state.adminAdmins);
  const classes = useGlobalStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [openRoles, setOpenRoles] = useState({ status: false, id: null });
  const [showAddTeamDialog, setShowAddTeamDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showAddProjectDialog, setShowAddProjectDialog] = useState(false);

  const { displayPanel, setHeaderPanels } = useSidebar();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{row.original.name}</Typography>
            <Typography variant="caption">{row.original.email}</Typography>
          </Box>
        ),
      },
      {
        Header: 'Project Membership',
        accessor: (rowData) => {
          const { grants } = rowData;
          return (
            <div className={classes.avatarRow}>
              {grants.slice(0, 3).map((grant) => (
                <Avatar initials={getInitials(`${grant.projectName}`)} size={30} key={grant.id} />
              ))}
              {grants.length > 3 ? <Avatar initials={`+${grants.length - 3}`} size={30} /> : null}
            </div>
          );
        },
      },
      {
        Header: 'Telephone',
        accessor: 'phone',
      },
    ],
    []
  );

  const fetchMembers = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await OrganizationService.getOrganizationMembers(org.id);
      const formattedMembers = data.users.map((member) => ({
        id: member.id,
        name: `${member.firstName} ${member.lastName}`,
        email: member.email,
        projectCount: member.grants.filter((grant) => grant.objectType === 'PROJ')?.length || 0,
        phone: member.phone || 'N/A',
        grants: member.grants,
      }));
      setMembers(formattedMembers);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  }, [org.id]);

  useEffect(() => {
    fetchMembers();
    if (onInit) {
      onInit(fetchMembers);
    }
  }, [fetchMembers, onInit]);

  const table = useTable(
    {
      columns,
      data: members,
    },
    useSortBy
  );

  const handleSelectedIds = (ids) => {
    setSelectedRows(ids);
    dispatch(setSelectedMembers(ids));
  };

  const handleCopyUuid = (uuid) => {
    setSelectedUuid(uuid);
    setShowCopyModal(true);
  };

  const handleCloseCopyModal = () => {
    setShowCopyModal(false);
    setSelectedUuid(null);
  };

  const handleRowClick = (row) => {
    const member = {
      firstName: row.original.name.split(' ')[0],
      lastName: row.original.name.split(' ').slice(1).join(' '),
      email: row.original.email,
    };
    setHeaderPanels([
      {
        name: 'memberDetail',
        component: MemberDetailPanel,
        props: { member },
      },
    ]);
    displayPanel('memberDetail');
  };

  const handleDeleteUser = async () => {
    try {
      if (userToDelete) {
        await OrganizationService.deleteMember(org.id, userToDelete.id);
        setMembers(members.filter((member) => member.id !== userToDelete.id));
        setShowDeleteConfirm(false);
        setUserToDelete(null);
      }
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleOpenDeleteConfirm = (user) => {
    setUserToDelete(user);
    setShowDeleteConfirm(true);
  };

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [memberRoles, setMemberRoles] = useState({});

  const handleAddToTeam = (user) => {
    setSelectedMember(user);
    setShowAddTeamDialog(true);
  };

  const handleAddToProject = (user) => {
    setSelectedMember(user);
    setShowAddProjectDialog(true);
  };

  const fetchData = (filterData) => {
    const sendFilters = {
      ...filterData,
      sort_by: Object.values(filterData.sort_by),
    };
    dispatch(fetchUsers(sendFilters));
    dispatch(fetchUser(sendFilters));
  };

  const MemberActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleCopyUuid(row.original.id)}>Copy UUID</ContextMenuItem>
        <ContextMenuItem onClick={() => handleAddToTeam(row.original)}>Add to Team</ContextMenuItem>
        <ContextMenuItem onClick={() => handleAddToProject(row.original)}>Add to Project</ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            setOpenRoles({ status: true, id: row.original.id });
          }}
        >
          Edit User Info & Participation
        </ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem onClick={() => handleOpenDeleteConfirm(row.original)}>Delete User</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  return (
    <Card noPadding>
      <MembersFilters
        selectedRows={selectedRows}
        org={org}
        onMembersUpdate={fetchMembers}
        members={members}
        showProjectModal={showProjectModal}
        setShowProjectModal={setShowProjectModal}
        showTeamModal={showTeamModal}
        setShowTeamModal={setShowTeamModal}
        memberRoles={memberRoles}
        setMemberRoles={setMemberRoles}
      />
      <GeneralTable
        table={table}
        selectRow
        handleSelectedIds={handleSelectedIds}
        rowComponent={MemberActions}
        loading={loading}
        onRowClick={handleRowClick}
      />
      {showCopyModal && <CopyUuidAlert id={selectedUuid} handleClose={handleCloseCopyModal} />}

      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)} maxWidth="sm" fullWidth>
        <Box sx={{ mt: 1, ml: 1 }}>
          <AlertTriangleIcon size={28} stroke="#e57373" background="#fff9c4" />
        </Box>
        <DialogTitle variant="h4">Delete {userToDelete?.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Do you really want to delete {userToDelete?.name} from the project? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {openRoles.status &&
        createPortal(
          <PermsSlide
            handleClose={(val) => {
              setOpenRoles({ status: false, id: null });
              if (val) fetchData(filters);
            }}
            userId={openRoles.id}
          />,
          document.getElementById('layout-area')
        )}
      <DialogAddUser
        isOpen={showAddTeamDialog}
        handleClose={() => {
          setShowAddTeamDialog(false);
          setSelectedMember(null);
          fetchMembers();
        }}
        type={typeInviteData.TEAM}
        userId={selectedMember?.id}
        userName={selectedMember?.name}
      />
      <DialogAddUser
        isOpen={showAddProjectDialog}
        handleClose={() => {
          setShowAddProjectDialog(false);
          setSelectedMember(null);
          fetchMembers();
        }}
        type={typeInviteData.PROJET}
        userId={selectedMember?.id}
        userName={selectedMember?.name}
      />
    </Card>
  );
};

Members.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onInit: PropTypes.func,
};

Members.defaultProps = {
  onInit: null,
};
