import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProjectService from 'services/ProjectService';
import { ObservationMap } from 'components/Observation/ObservationMap';
import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/TabPanel';
import { Time } from 'lib/time';
import moment from 'moment';
import { Permissions } from 'lib/permissions';
import { UuidButton } from 'components/UuidButton/UuidButton';
import { CloseIcon, PencilIcon } from 'components/Icons';
import { ObservationModal } from 'components/Observation/ObservationModal/ObservationModal';
import { EmailButon } from 'components/Observation/ObservationEmail/EmailButon';
import { useDispatch } from 'react-redux';
import { setObservation } from 'slices/observationsReducer';
import { ObservationExport } from 'components/Observation/ObservationExport/ObservationExport';
import { useStyles } from '../styles';
import { ImageViewer } from './ImageViewer';

export const ProjectObservationDetail = ({ observation, point, observations, isObservationLoading, closeButton }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataUrl, setDataUrl] = useState(null);
  const [imgList, setImgList] = useState([]);
  const [open, setOpen] = useState(false);
  const [multipleImg, setMultipleImg] = useState(false);
  const [tab, setTabValue] = useState(0);
  const [map, setMap] = useState(null);

  // ----------------------- get sequence data
  const fetchImage = async (auxSequence) => {
    try {
      const auxObservation = auxSequence;
      // ** added the validation for sequence in the object
      if (auxObservation.sequence && auxObservation.sequence.assetId) {
        const result = await ProjectService.getProjectAssetObservationImage(
          auxObservation.projectId,
          auxObservation.sequence.assetId,
          auxObservation.id
        );
        setDataUrl(URL.createObjectURL(result.data));
      } else if (auxObservation.images && auxObservation.images.length > 1) {
        // display multiple images from project observation
        setMultipleImg(true);
        const sorted = auxObservation.images.toSorted((a, b) => a.order - b.order);
        setImgList(sorted);
      } else if (auxObservation.images && auxObservation.images.length === 1) {
        // display single image from project observation (from array)
        setMultipleImg(false);
        setDataUrl(auxObservation.images[0].imagePath);
      } else {
        // display single image from project observation
        setMultipleImg(false);
        setDataUrl(auxObservation.imagePath);
      }
    } catch (err) {
      // do something with err
    }
  };

  useEffect(() => {
    fetchImage(observation);
  }, [observation]);

  useEffect(() => {
    setTabValue(0);
  }, [observation?.id]);

  return (
    <div className={classes.containerDetail}>
      <div className={classes.topButton}>
        <EmailButon observation={observation} map={map} color="primary" />
        {!closeButton && (
          <ObservationExport
            observations={observations.length ? observations : [observation]}
            isLoading={isObservationLoading}
          />
        )}
        {closeButton && (
          <div className={classes.obsCloseButtonGrapper}>
            <ObservationExport
              observations={observations.length ? observations : [observation]}
              isLoading={isObservationLoading}
            />
            <Button
              onClick={() => dispatch(setObservation({}))}
              color="primary"
              variant="contained"
              size="small"
              sx={{ padding: '8px 14px' }}
            >
              <CloseIcon size={20} />
              <Typography ml={0.3}>Close</Typography>
            </Button>
          </div>
        )}
      </div>
      <Grid container spacing={0} className={`${classes.mapDetailContainer} mapDetailContainer`}>
        <Grid item lg={8} md={8} sm={12} xs={12} className={classes.fullHeight}>
          {!multipleImg && <img src={dataUrl} alt="" className={classes.imageContent} />}
          {multipleImg && <ImageViewer images={imgList} />}
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.fullHeight}>
          <ObservationMap
            observation={observation}
            point={point}
            enableEdit={false}
            fullHeight
            imgHandler={(view) => setMap(view)}
          />
        </Grid>
      </Grid>
      <div className={classes.tabsDetail}>
        {observation?.id && (
          <>
            <Tabs value={tab} className={classes.headerTabs}>
              <Tab label="Overview" onClick={() => setTabValue(0)} />
              <Tab label="Advanced" onClick={() => setTabValue(1)} />
            </Tabs>
            <div className={classes.bodyDetail}>
              <TabPanel value={tab} index={0}>
                <Typography variant="h4">{observation.name}</Typography>
                <Typography mb={0.5}>Observation description: {observation.description}</Typography>
                <Typography mt={1} variant="h5">
                  Source Name: {observation.asset?.name || observation?.name}
                </Typography>
                <Typography mb={0.5}>Timecode Marker: {Time.humanReadable({ seconds: observation.time })}</Typography>
                <Typography mt={1} variant="h5">
                  Created By: {`${observation.user?.firstName} ${observation.user?.lastName}`}
                </Typography>
                <Typography mb={0.5}>Creation Date: {moment(observation.createdAt).format('MM/DD/YY')}</Typography>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <UuidButton
                      data={{ id: observation.projectId }}
                      permissions={[Permissions.PROJ_OBSERVATION]}
                      buttonType
                    />
                  </Grid>
                  {observation?.sequence && observation?.sequence?.assetId && (
                    <Grid item xs={12}>
                      <Button className={classes.buttonGrapper} onClick={() => setOpen(true)}>
                        <Button color="secondary" variant="contained" className={classes.smallButton} size="small">
                          <PencilIcon size={15} />
                        </Button>
                        Edit
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </div>
          </>
        )}
      </div>
      {open && (
        <ObservationModal
          isOpen
          handleClose={() => setOpen(false)}
          observationMap={observation}
          pointMap={point}
          container="sidebar-panel"
        />
      )}
    </div>
  );
};

ProjectObservationDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  observation: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  point: PropTypes.any,
  observations: PropTypes.array,
  isObservationLoading: PropTypes.bool,
  closeButton: PropTypes.bool,
};

ProjectObservationDetail.defaultProps = {
  observation: { id: null },
  point: { id: null, latitue: 0, longitude: 0 },
  isObservationLoading: false,
  observations: [],
  closeButton: false,
};
