import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ProjectService from 'services/ProjectService';

export const ModalAddProjects = ({ isOpen, onCancel, onConfirm, orgId, orgName }) => {
  const [loading, setLoading] = useState(false);
  const [newProject, setNewProject] = useState({ name: '', description: '' });

  const clearForm = () => {
    setNewProject({ name: '', description: '' });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        name: newProject.name,
        description: newProject.description,
        orgId,
      };
      await ProjectService.addProjectOrganization(data);
      clearForm();
      onConfirm();
    } catch (error) {
      console.error('Error adding users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    clearForm();
    onCancel();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} maxWidth="sm">
      {loading && <LoadingOverlay />}
      <DialogTitle>
        <ModalHeaderIcon icon={UserPlusIcon} text="Create Project" color="success" />
      </DialogTitle>
      <DialogContent sx={{ width: '480px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
          <Box sx={{ width: '100%' }}>
            <span>Name</span>
            <TextField
              label="Current Name"
              value={newProject.name}
              onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
              size="small"
              sx={{ flex: 1, width: '100%', mt: 0.2 }}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <span>Description</span>
            <TextField
              label="Project Description"
              value={newProject.description}
              onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
              size="small"
              sx={{ flex: 1, width: '100%', mt: 0.2 }}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <span>Organization</span>
            <Select value={orgId} disabled sx={{ width: '100%', mt: 0.2 }} size="small">
              <MenuItem value={orgId}>{orgName}</MenuItem>
            </Select>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 1.2, pb: 1, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button onClick={handleCancel} variant="contained" sx={{ width: '50%', height: '36px' }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#FF9100',
            color: '#000',
            '&:hover': {
              backgroundColor: '#FF9100',
              opacity: 0.9,
            },
            width: '50%',
            height: '36px',
          }}
          disabled={newProject.name === '' || newProject.description === ''}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalAddProjects.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};
