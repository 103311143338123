import ApiService from './ApiService';

export default class MapGisService {
  static getMapGisServices = (filters) => ApiService.get('/gis', filters);

  static getMapGisService = async (id) => ApiService.get(`/gis/${id}`);

  static saveMapGisService = (data) => ApiService.post('/arcgis', data);

  static activateMapGisService = async (id, active) => ApiService.patch(`/arcgis/${id}/activate`, { active });

  static getMapGisServicesAll = (filters) => ApiService.get('/arcgis', filters);

  /**
   * Update the Map GIS or External Map information.
   *
   * @param {string} id
   * @param {string[]} data
   * @returns
   */
  static updateMapGisService = async (id, data) => ApiService.patch(`/arcgis/${id}`, data);

  static deleteMap = async (id) => ApiService.delete(`/arcgis/${id}/delete`);
}
