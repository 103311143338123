/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapGis } from 'components/MapGis/MapGis';
import { mapGisFilterButtons } from 'pages/Admin/MapGis/helpers';
import { getMapGisServicesAll, preloadFilterButtons } from 'slices/mapGisReducer';
import PropTypes from 'prop-types';

let debounceTimeout = null;

export const ProjectMapGis = ({ project }) => {
  const { filters } = useSelector((state) => state.adminMapGis);
  const dispatch = useDispatch();

  const loadData = () => {
    dispatch(getMapGisServicesAll({ filters }));
    dispatch(preloadFilterButtons({ exclude: '' }));
  };

  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      dispatch(getMapGisServicesAll({ filters, exclude: '' }));
      dispatch(preloadFilterButtons({ exclude: '' }));
    }, 300);
  }, [filters]);

  return <MapGis section={mapGisFilterButtons.PROJ} project={project} loadData={loadData} />;
};

ProjectMapGis.propTypes = {
  project: PropTypes.object,
};

ProjectMapGis.defaultProps = {
  project: {},
};
