import axios from 'axios';
import qs from 'qs';

const skipRedirect = ['/login'];

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let redirect = false;
    const current = window.location.href;
    const site = window.location.origin.toLowerCase();
    // eslint-disable-next-line no-restricted-syntax
    for (const url of skipRedirect) {
      if (current.indexOf(`${site}${url}`) !== -1) {
        redirect = false;
        break;
      }
    }
    if (redirect) {
      switch (error?.response?.status) {
        case 500:
          window.location.replace(`${window.location.origin}/500`);
          break;
        case 401:
        case 403:
          window.location.replace(`${window.location.origin}/401`);
          break;
        case 410: // If JWT token is malformed/expired (should be 401, but we use 401 for everything)
          window.location.replace(`${window.location.origin}/login`);
          localStorage.clear();
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default class ApiService {
  static baseUrl = `${process.env.REACT_APP_API_URL}`;

  static getConfigs = (additionalConfig = {}) => {
    const config = {
      ...defaultConfig,
      ...additionalConfig,
    };

    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken !== null) {
      config.headers = { Authorization: `Bearer ${user.accessToken}` };
    }

    return config;
  };

  /**
   *
   * @param {string} url
   * @param {Record<string, any>} params
   * @param {Record<string, any>} config
   *
   * @returns
   */
  static get = async (url, params, config = {}) => axios.get(url, this.getConfigs({ params, ...config }));

  static post = async (url, data, configs = {}) => axios.post(url, data, this.getConfigs(configs));

  static put = async (url, data) => axios.put(url, data, this.getConfigs());

  static patch = async (url, data) => axios.patch(url, data, this.getConfigs());

  static delete = async (url) => axios.delete(url, this.getConfigs());

  static download = async (url) => axios.get(url, this.getConfigs());

  static upload = async (url, data) => {
    const config = this.getConfigs();
    config.headers['content-type'] = 'multipart/form-data';
    return axios.post(url, data, config);
  };
}
