import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preloadFilterDatasets, setFilterValue, setFilters } from 'slices/mapGisReducer';
import { SearchPanelBody } from './SearchPanelBody';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  /* TODO - create arcgis slices */
  const { addedBy, loaded } = useSelector((state) => state.adminMapGis.panels.search);
  const { filters } = useSelector((state) => state.adminMapGis);

  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SearchPanelBody
      loaded={loaded}
      users={addedBy}
      filters={filters}
      setFilterValue={setFilterValue}
      setFilters={setFilters}
    />
  );
};
