import { Svg } from './Svg';
import React from 'react';

export const LinkedFilesAddIcon = () => (
  <Svg viewBox="0 0 22 22">
    <path
      d="M9.66665 9.16602H4.66665M6.33331 12.4993H4.66665M11.3333 5.83268H4.66665M14.6666 5.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3942 16.9677C14.1545 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0668 18.3327 10.6666 18.3327H5.33331C3.93318 18.3327 3.23312 18.3327 2.69834 18.0602C2.22793 17.8205 1.84548 17.4381 1.6058 16.9677C1.33331 16.4329 1.33331 15.7328 1.33331 14.3327V5.66602C1.33331 4.26588 1.33331 3.56582 1.6058 3.03104C1.84548 2.56063 2.22793 2.17818 2.69834 1.9385C3.23312 1.66602 3.93318 1.66602 5.33331 1.66602H10.6666C12.0668 1.66602 12.7668 1.66602 13.3016 1.9385C13.772 2.17818 14.1545 2.56063 14.3942 3.03104C14.6666 3.56582 14.6666 4.26588 14.6666 5.66602Z"
      stroke="#303030"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Svg>
);
