export const PROJECT_TABS = {
  PROJECT_INFO: 0,
  PROJECT_ASSETS: 1,
  PROJECT_OBSERVATIONS: 2,
  PARTICIPANT_COORDINATION: 3,
  PROJECT_MAP_GIS: 4,
  PROJECT_ACTIVE_INSPECTIONS: 5,
  PROJECT_LINKED_FILES: 6,
  COMMING_SOON_1: 7,
  COMMING_SOON_2: 8,
  PROJECT_SETTINGS: 9,
};
