import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography } from '@mui/material';
import { FeatureWarningIcon, CloseIcon } from 'components/Icons';
import { Loader } from 'components/Loader';
import { useStyles } from 'smartComponents/ModalLinkToMapOrServices/styles';
import MapGisService from 'services/MapGisService';
import PropTypes from 'prop-types';

export const ModalDeleteMapGis = ({ open, data, handleOpen }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleLocalOpen = () => {
    handleOpen(false);
  };

  const handleRequest = () => {
    setLoading(true);
    MapGisService.deleteMap(data?.id).then(() => {
      handleOpen(false, true);
      setLoading(false);
    });
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <FeatureWarningIcon size={50} />
          <Button
            onClick={handleLocalOpen}
            className={classes.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
        <Typography variant="h4">Delete {data?.mapName}</Typography>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <Loader loading={loading} height={100} size={32} />
          </div>
        ) : (
          <Typography variant="caption">
            Deleted are moved inthe the &apos;Pending Delete&apos; page for a period designated by the project, Org, or
            Account. During this time the deletion can be cancelled. This also provides time for any parties sharing
            this object to copy it.
          </Typography>
        )}
      </DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button
            onClick={handleLocalOpen}
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
          >
            Cancel
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button
            onClick={handleRequest}
            disabled={loading}
            variant="contained"
            color="error"
            fullWidth
            disableElevation
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ModalDeleteMapGis.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  handleOpen: PropTypes.func,
};

ModalDeleteMapGis.defaultProps = {
  open: false,
  data: null,
  handleOpen: () => {},
};
