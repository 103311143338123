import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const SSOSigninIcon = ({ size }) => (
  <Svg viewBox="0 0 24 24" size={size} fill="transparent">
    <g id="key-01">
      <path
        id="Icon"
        d="M17.5 8.99994C17.5 8.48812 17.3047 7.9763 16.9142 7.58579C16.5237 7.19526 16.0118 7 15.5 7M15.5 15C18.8137 15 21.5 12.3137 21.5 9C21.5 5.68629 18.8137 3 15.5 3C12.1863 3 9.5 5.68629 9.5 9C9.5 9.27368 9.51832 9.54308 9.55381 9.80704C9.61218 10.2412 9.64136 10.4583 9.62172 10.5956C9.60125 10.7387 9.5752 10.8157 9.50469 10.9419C9.437 11.063 9.31771 11.1823 9.07913 11.4209L3.96863 16.5314C3.79568 16.7043 3.7092 16.7908 3.64736 16.8917C3.59253 16.9812 3.55213 17.0787 3.52763 17.1808C3.5 17.2959 3.5 17.4182 3.5 17.6627V19.4C3.5 19.9601 3.5 20.2401 3.60899 20.454C3.70487 20.6422 3.85785 20.7951 4.04601 20.891C4.25992 21 4.53995 21 5.1 21H7.5V19H9.5V17H11.5L13.0791 15.4209C13.3177 15.1823 13.437 15.063 13.5581 14.9953C13.6843 14.9248 13.7613 14.8987 13.9044 14.8783C14.0417 14.8586 14.2588 14.8878 14.693 14.9462C14.9569 14.9817 15.2263 15 15.5 15Z"
        stroke="#B1B1B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
);

SSOSigninIcon.propTypes = {
  size: PropTypes.number,
};

SSOSigninIcon.defaultProps = {
  size: 24,
};
