import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import { FilterIcon, GridIcon, ListIcon, SearchIcon } from 'components/Icons';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { SidebarContext } from '../SidebarProvider/SidebarProvider';
import { useStyles } from './styles';

export const TopBar = ({
  options,
  tableCardToggle,
  // eslint-disable-next-line no-unused-vars
  handleActions,
  handleSearch,
  handleToggle,
  searchValue,
  activatedFilter,
  sideBarDetail,
  credit,
  filterButton,
  buttonGroup,
  arcgis,
}) => {
  const classes = useStyles({ flex: (options && options.length > 0) || tableCardToggle ? 'space-between' : 'end' });
  const [optionValue, setOptionValue] = useState(null);
  const { setActivePanel, setFullWidth } = useContext(sideBarDetail ? SidebarDetailContext : SidebarContext);
  const [tableToggle, setTableToggle] = useState(true);

  const handleOptionChange = (value) => {
    setOptionValue(value);
  };

  const handleLocalToggle = () => {
    setTableToggle(!tableToggle);
    handleToggle(!tableToggle);
  };

  const localClasses = () => {
    if (credit) return `${classes.bar} ${classes.credit}`;
    if (arcgis) return `${classes.bar} ${classes.arcgis}`;
    return classes.bar;
  };

  return (
    <div className={localClasses()}>
      <div>
        {options && options.length > 0 && false && (
          <FormControl className={classes.select}>
            <Autocomplete
              options={options}
              value={optionValue}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} placeholder="Select Option" />}
              onChange={(e, value) => {
                handleOptionChange(value?.value ? value : null);
              }}
            />
          </FormControl>
        )}
        {tableCardToggle &&
          (!tableToggle ? (
            <Button
              onClick={() => handleLocalToggle()}
              className={classes.button}
              color="primary"
              variant="contained"
              disableElevation
            >
              <ListIcon size={15} />
            </Button>
          ) : (
            <Button
              onClick={() => handleLocalToggle()}
              className={classes.button}
              color="primary"
              variant="contained"
              disableElevation
            >
              <GridIcon size={15} />
            </Button>
          ))}
        {buttonGroup}
      </div>
      <div className={classes.filters}>
        <TextField
          variant="outlined"
          placeholder="Search"
          className={classes.input}
          InputProps={{
            startAdornment: <SearchIcon size={20} />,
          }}
          value={searchValue}
          onChange={handleSearch}
        />
        {filterButton && (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            className={`${classes.filterButton} ${activatedFilter && classes.activatedFilter}`}
            onClick={() => {
              setFullWidth(false);
              setActivePanel('search');
            }}
            disableElevation
          >
            <FilterIcon size={15} /> Filters {activatedFilter && 'On'}
          </Button>
        )}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any,
  tableCardToggle: PropTypes.bool,
  handleActions: PropTypes.func,
  handleSearch: PropTypes.func,
  searchValue: PropTypes.string,
  handleToggle: PropTypes.func,
  activatedFilter: PropTypes.bool,
  sideBarDetail: PropTypes.bool,
  credit: PropTypes.bool,
  filterButton: PropTypes.bool,
  buttonGroup: PropTypes.node,
  arcgis: PropTypes.bool,
};

TopBar.defaultProps = {
  options: [],
  tableCardToggle: false,
  handleActions: () => {},
  handleSearch: () => {},
  handleToggle: () => {},
  searchValue: '',
  activatedFilter: false,
  sideBarDetail: false,
  credit: false,
  filterButton: true,
  buttonGroup: null,
  arcgis: false,
};
