import React, { useContext, useState } from 'react';
import { Button, Checkbox, Typography, ListSubheader } from '@mui/material';
import { ContextMenu, ContextMenuContext } from 'components/ContextMenu';
import { ContextMenuList } from 'components/ContextMenuItems';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { FiltersIcon } from 'components/Icons';
import { useStyles as useTopBarStyles } from 'components/TopBar/styles';
import { v4 } from 'uuid';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

const FiltersButton = () => {
  const { buttonRef, toggleMenu } = useContext(ContextMenuContext);
  const classes = useTopBarStyles();

  return (
    <Button
      onClick={() => toggleMenu()}
      className={`${classes.filtersButtonBar} ${classes.button}`}
      ref={buttonRef}
      variant="contained"
      color="primary"
      disableElevation
    >
      <FiltersIcon />
    </Button>
  );
};

/**
 * This component will render a list with object array to sort a list
 * @param {Object} param
 * @returns new ```<ContextMenu />``` component
 */
export const Filters = ({ title, options, onChangeSelected }) => {
  const [selected, setSelected] = useState();
  const classes = useStyles();

  const onHandleSelected = (checked, index) => {
    const option = options[index];
    if (option && checked) {
      setSelected(index);
      onChangeSelected(option);
    } else {
      setSelected('');
    }
  };

  return (
    <ContextMenu>
      <FiltersButton />
      <ContextMenuList
        subHeader={<ListSubheader className={classes.subHeader}>{title}</ListSubheader>}
        position="bottom-right"
      >
        {options.map((option, index) => (
          <ContextMenuItem key={`${v4()}${new Date().getMilliseconds()}`} disableRipple>
            <div key={`${v4()}${new Date().getMilliseconds()}`} className="itemList">
              <Checkbox
                onChange={(event) => onHandleSelected(event.target.checked, index)}
                checked={index === selected}
                color="secondary"
              />
              <Typography>{option.label}</Typography>
            </div>
          </ContextMenuItem>
        ))}
      </ContextMenuList>
    </ContextMenu>
  );
};

Filters.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  onChangeSelected: PropTypes.func,
};

Filters.defaultProps = {
  title: 'Sort By',
  options: [],
  onChangeSelected: () => {},
};
