/**
 * Class to get the labels for the kml object wheter point or line files.
 * Version: 0.0.2
 */
import { v4 } from 'uuid';
import * as JSZip from 'jszip';

class SamKMLLayer {
  /**
   * private global variables
   */
  #url = '';

  #type = '';

  #sourceJSON = {};

  #isResolved = false;

  #layers = {
    folderName: 'default',
    items: [],
  };

  /**
   * The constructor receive an object,
   * for this momento only is received the URL for the object
   * @param {Object} url
   */
  constructor({ url }) {
    this.#url = url;
    this.#validateURL();
  }

  /**
   * Here are defined the priavate methods
   */

  /**
   * Set the folder name in the object
   * @param {String} name
   */
  #setLayersFolderName(name) {
    this.#layers.folderName = name;
  }

  /**
   * Set the items to the layer object
   * @param {Array<Object>} items
   */
  #setLayersItems(items) {
    this.#layers.items.push(items);
  }

  /**
   * This function will return the object layer
   * @returns Object
   */
  #getLayers() {
    return this.#layers;
  }

  /**
   * This function validate the correct URL extension to allow kml and kmz files
   */
  #validateURL() {
    if (this.#url === '' || !this.#url.length || !String(this.#url)) {
      throw new Error('The URL should not be empty or bad format.');
    }
    const pathName = new URL(this.#url)?.pathname;
    const lastDot = pathName.lastIndexOf('.');
    const type = pathName.substring(lastDot + 1);
    this.#type = type;
    if (!['kml', 'kmz'].includes(type)) {
      throw new Error('The file extension must be `kml` or `kmz`');
    }
  }

  /**
   * This function receive a text - xml value and will return a new object
   * with the XML structure
   * @param {string} xml
   * @returns new object
   */
  #convertXMLToJSON(xml) {
    let json = {};
    if (xml.children.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < xml.children.length; i++) {
        const item = xml.children.item(i);
        const { nodeName } = item;

        if (typeof json[nodeName] === 'undefined') {
          json[nodeName] = this.#convertXMLToJSON(item);
        } else {
          if (typeof json[nodeName].push === 'undefined') {
            const old = json[nodeName];
            json[nodeName] = [];
            json[nodeName].push(old);
          }
          json[nodeName].push(this.#convertXMLToJSON(item));
        }
      }
    } else {
      json = xml.textContent;
    }
    return json;
  }

  /**
   * This function will process the parsed object applying the necesary
   * process to get the information, in this case return and object like the
   * following example.
   * ```jsx
   * {
   *  folderName: 'default' | 'Name in the JSON',
   *  items: [
   *    {
   *      description: 'Name of the object',
   *      center: {
   *        longitude: -0.0000, // Longitude of the object
   *        latitude: 0.0000 // Latitude of the object
   *      }
   *    }
   *  ]
   * }
   * ```
   * @returns new object with the folder name and items
   */
  #processData() {
    if (!Object.keys(this.#sourceJSON).length) {
      throw new Error('The JSON parsed is empty');
    }
    if (!Object.keys(this.#sourceJSON).length || !this.#sourceJSON?.kml) {
      throw new Error(`The source JSON not contains a right structure: ${this.sourceJSON}`);
    }
    const folders = this.#sourceJSON?.kml?.Document?.Folder || this.#sourceJSON?.kml?.Document || [] || {};
    let itemsFromFolder = [];

    if (Array.isArray(folders)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < folders.length; i++) {
        const subFolder = folders[i];
        if (subFolder?.Folder) {
          itemsFromFolder.push(subFolder?.Folder);
        }
        itemsFromFolder.push(subFolder);
      }
    } else if (typeof folders === 'object') {
      itemsFromFolder = [folders];
    }

    // getting the first folder name
    const folder = itemsFromFolder[0];
    if (folder?.name) {
      this.#setLayersFolderName(folder?.name);
    }

    // loop the items from folders
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itemsFromFolder.length; i++) {
      const { Placemark: placemark } = itemsFromFolder[i] || {};
      if (placemark) {
        const { MultiGeometry: multiGeometry } = placemark;
        if (multiGeometry) {
          const key = Object.keys(multiGeometry)[0];
          const values = Object.values(multiGeometry)[0];
          placemark[key] = values;
        }
        // validate if the placemark is an array
        if (Array.isArray(placemark)) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < placemark.length; j++) {
            const item = placemark[j];
            // validate if item is a point
            if (typeof item?.Point === 'object') {
              const { Point: point, description, name: pointName } = item;
              if (point) {
                const [longitude, latitude] = String(point?.coordinates || '0,0').split(',');
                // set the layer
                this.#setLayersItems({
                  id: v4(),
                  description: description || pointName || 'Default point description',
                  center: {
                    longitude: parseFloat(longitude),
                    latitude: parseFloat(latitude),
                  },
                });
              }
            }
            // validate if item is a line string
            if (typeof item?.LineString === 'object') {
              const simpleData = item?.ExtendedData?.SchemaData?.SimpleData;
              const { LineString: line, description } = item;
              const [longitude, latitude] = String(line?.coordinates || '0,0')
                .trim()
                .split(',');

              // set the layer
              this.#setLayersItems({
                id: v4(),
                description: simpleData ? simpleData[0] : description || 'Default line description',
                center: {
                  longitude: parseFloat(longitude),
                  latitude: parseFloat(latitude),
                },
              });
            }
          }
        }
        if (typeof placemark?.Point === 'object') {
          // work here
          const { Point: point, description, name: pointName } = placemark || {};
          if (point) {
            const [longitude, latitude] = String(point?.coordinates || '0,0').split(',');
            // set the layer
            this.#setLayersItems({
              id: v4(),
              description: description || pointName,
              center: {
                longitude: parseFloat(longitude),
                latitude: parseFloat(latitude),
              },
            });
          }
        }
        if (typeof placemark?.LineString === 'object') {
          // work here
          if (Object.keys(placemark?.LineString).length) {
            Object.keys(placemark?.LineString).forEach((attr) => {
              const line = placemark?.LineString[attr];
              const [longitude, latitude] = String(line.coordinates || '0,0')
                .trim()
                .split(',');
              // set the layer
              this.#setLayersItems({
                id: v4(),
                description: line.altitudeMode ? line.altitudeMode : 'Default line description',
                center: {
                  longitude: parseFloat(longitude),
                  latitude: parseFloat(latitude),
                },
              });
            });
          } else {
            const simpleData = placemark?.ExtendedData?.SchemaData?.SimpleData;
            const { LineString: line, description } = placemark || {};
            const [longitude, latitude] = String(line?.coordinates || '0,0')
              .trim()
              .split(',');

            // set the layer
            this.#setLayersItems({
              id: v4(),
              description: simpleData ? simpleData[0] : description || 'Default line description',
              center: {
                longitude: parseFloat(longitude),
                latitude: parseFloat(latitude),
              },
            });
          }
        }
      }
    }
    this.#isResolved = true;
  }

  /**
   * Here are defined the public methods
   */

  /**
   * This function returns a boolean when the process functions
   * finished process it
   * @returns boolean
   */
  isResolved() {
    return this.#isResolved;
  }

  /**
   * This is the main function to return the object processed
   * @returns Promise
   */
  when() {
    return fetch(this.#url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Problems fetching the file.');
        }
        if (['kmz'].includes(this.#type)) {
          return response.blob();
        }
        return response.text();
      })
      .then(async (response) => {
        let responseHttp = response;
        if (['kmz'].includes(this.#type)) {
          const zip = new JSZip();
          responseHttp = await zip.loadAsync(response).then((value) => {
            const kml = Object.values(value.files)[0];
            return kml.async('string').then((result) => result);
          });
        }
        const parser = new DOMParser();
        const xml = parser.parseFromString(responseHttp, 'text/xml');
        const json = this.#convertXMLToJSON(xml);
        if (!json?.kml) {
          window.console.error(json?.html?.body?.parsererror?.div);
          return null;
        }
        // assign to the global variable in the class
        this.#sourceJSON = json;
        this.#processData();
        return this.#getLayers();
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default SamKMLLayer;
