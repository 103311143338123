import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { setFilters } from 'slices/membersReducer';

const roleOptions = [
  { value: 'proj:admin', label: 'Project Admin' },
  { value: 'proj:content_coord', label: 'Content Coordinator' },
  { value: 'proj:part_coord', label: 'Participant Coordinator' },
];

export const MembersSearchPanel = ({ filters, projects = [] }) => {
  const dispatch = useDispatch();
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {
      name: filters.sort_by?.name || '',
    },
    roles: filters.roles || [],
    teams: filters.teams || [],
    favorites: filters.favorites || false,
  });

  const clearSort = (param) => {
    const auxFilters = { ...localFilters };
    delete auxFilters.sort_by[param];
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    setLocalFilters(auxFilters);
  };

  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      sort_by: { name: '' },
      roles: [],
      teams: [],
      favorites: false,
      activated: false,
    };
    setLocalFilters(clearedFilters);
    dispatch(setFilters(clearedFilters));
  };

  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    auxFilters.activated = filterActions.length > 0;
    dispatch(setFilters(auxFilters));
  };

  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters.sort_by?.name) {
      auxFilters.push({ text: 'Sort By Name', callBack: () => clearSort('name') });
    }
    if (clearFilters.roles?.length > 0) {
      auxFilters.push({ text: 'Clear Roles', callBack: () => updateNormalFilters([], 'roles') });
    }
    if (clearFilters.teams?.length > 0) {
      auxFilters.push({ text: 'Clear Teams', callBack: () => updateNormalFilters([], 'teams') });
    }
    if (clearFilters.favorites) {
      auxFilters.push({ text: 'Clear Favorites', callBack: () => updateNormalFilters(false, 'favorites') });
    }
    return auxFilters;
  };

  useEffect(() => {
    const auxFilters = createClearFilters(localFilters);
    setFiltersActions(auxFilters);
  }, [localFilters]);

  useEffect(() => {
    const auxFilters = createClearFilters(filters);
    setFiltersActions(auxFilters);
    setLocalFilters({
      sort_by: {
        name: filters.sort_by?.name || '',
      },
      roles: filters.roles || [],
      teams: filters.teams || [],
      favorites: filters.favorites || false,
    });
  }, [filters]);

  return (
    <SidebarPanel
      title="Filters"
      subTitle="Apply filters to table data."
      filters
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />

        <FormControl>
          <FormLabel>Sort</FormLabel>
          <RadioGroup
            row
            value={localFilters.sort_by?.name || ''}
            onChange={(e) => {
              const sortBy = { ...localFilters.sort_by };
              sortBy.name = e.target.value;
              updateNormalFilters(sortBy, 'sort_by');
            }}
          >
            <FormControlLabel value="+name" control={<Radio />} label="A - Z" />
            <FormControlLabel value="-name" control={<Radio />} label="Z - A" />
            <FormControlLabel value="" control={<Radio />} label="None" />
          </RadioGroup>
        </FormControl>

        <FormControl className="marginTop">
          <FormLabel>Favorites</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={localFilters.favorites}
                onChange={(e) => updateNormalFilters(e.target.checked, 'favorites')}
              />
            }
            label="Only Show Favorites"
          />
        </FormControl>

        <FormControl className="marginTop">
          <FormLabel>Roles</FormLabel>
          <div>
            {roleOptions.map((role) => (
              <FormControlLabel
                key={role.value}
                control={
                  <Checkbox
                    checked={localFilters.roles?.includes(role.value)}
                    onChange={(e) => {
                      const newRoles = e.target.checked
                        ? [...(localFilters.roles || []), role.value]
                        : localFilters.roles?.filter((r) => r !== role.value) || [];
                      updateNormalFilters(newRoles, 'roles');
                    }}
                  />
                }
                label={role.label}
              />
            ))}
          </div>
        </FormControl>

        <FormControl className="marginTop">
          <FormLabel>Find Member by Project Membership</FormLabel>
          <TextField
            placeholder="Search"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 1 }}
          />
          <div className="scrollableCheckboxes">
            {projects.map((project) => (
              <FormControlLabel
                key={project.value}
                control={
                  <Checkbox
                    checked={localFilters.teams?.includes(project.value)}
                    onChange={(e) => {
                      const newTeams = e.target.checked
                        ? [...(localFilters.teams || []), project.value]
                        : localFilters.teams?.filter((t) => t !== project.value) || [];
                      updateNormalFilters(newTeams, 'teams');
                    }}
                  />
                }
                label={project.label}
              />
            ))}
          </div>
        </FormControl>
      </div>
    </SidebarPanel>
  );
};
