import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import * as localForage from 'localforage';
import MapGisService from 'services/MapGisService';

export const ActiveMapGis = ({ on, webmap }) => {
  const [toggle, setToggle] = useState(on);
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setToggle(!toggle);
    setDisabled(true);
    await MapGisService.activateMapGisService(webmap.id, !toggle);
    // set Map Switch
    await localForage.setItem('webmap', webmap);
    setDisabled(false);
  };

  return (
    <FormControlLabel
      control={<Switch color="secondary" />}
      onChange={() => handleClick()}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      checked={toggle}
      disabled={disabled}
      value="isActive"
    />
  );
};
