import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/Table';
import { ArrowNarrowDownIcon } from 'components/Icons';
import { Checkbox, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAlphaAsc, faSortAlphaDesc } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { theme } from 'theme';

export const GeneralTable = ({
  table,
  selectedRow,
  handleRowClick,
  rowComponent: RowComponent,
  actionFunction,
  handleSelectedIds,
  selectRow,
  external,
  actionHeaderText,
  autoHeight,
  dragAndDropEnabled,
  customizedRow: CustomizedRow,
  credit,
  payment,
  arcgis,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [data, setData] = useState([...table.rows]);

  useEffect(() => {
    if (dragAndDropEnabled) setData(...table.rows);
  }, [table]);

  // eslint-disable-next-line no-unused-vars
  const dragStart = (e) => {
    dragItem.current = e.target.id;
  };
  // eslint-disable-next-line no-unused-vars
  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id;
  };
  // eslint-disable-next-line no-unused-vars
  const drop = () => {
    const copyListItems = [...data];
    const dragItemCont = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemCont);
    dragItem.current = null;
    dragOverItem.current = null;
    setData(copyListItems);
  };

  const handleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        const selected = prevSelectedRows.filter((id) => id !== rowId);
        handleSelectedIds(selected);
        return selected;
      }
      const selected = [...prevSelectedRows, rowId];
      handleSelectedIds(selected);
      return selected;
    });
  };

  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      const allRowIds = table.data.map((row) => row.id);
      handleSelectedIds(allRowIds);
      setSelectedRows(allRowIds);
    } else {
      handleSelectedIds([]);
      setSelectedRows([]);
    }
  };

  const isRowSelected = (rowId) => selectedRows.includes(rowId);

  const styleBox = { width: '40px', padding: '15px 0px 15px 15px' };
  const styleCheckBox = { padding: 0 };

  return (
    <Table table={table} height={!autoHeight} credit={credit} payment={payment}>
      <TableHead>
        {table.headerGroups.map((headerGroup) => (
          <TableRow key={`${v4()}${new Date().getMilliseconds()}`} {...headerGroup.getHeaderGroupProps()}>
            {selectRow && (
              <TableCell style={styleBox}>
                <Checkbox
                  id={v4()}
                  checked={selectedRows.length === table.data.length}
                  onChange={handleSelectAllRows}
                  color="secondary"
                  style={styleCheckBox}
                />
              </TableCell>
            )}
            {headerGroup.headers.map((column) => (
              <TableCell
                key={`${v4()}${new Date().getMilliseconds()}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span
                  style={{
                    ...(credit || payment || arcgis ? { marginLeft: '4px' } : { margin: '2px 4px' }),
                    position: 'absolute',
                  }}
                >
                  {(() => {
                    if (column.isSorted) {
                      if (column.isSortedDesc) {
                        return credit || payment || arcgis ? (
                          <ArrowNarrowDownIcon />
                        ) : (
                          <FontAwesomeIcon icon={faSortAlphaDesc} size="sm" />
                        );
                      }
                      return credit || payment || arcgis ? (
                        <ArrowNarrowDownIcon />
                      ) : (
                        <FontAwesomeIcon icon={faSortAlphaAsc} size="sm" />
                      );
                    }
                    return credit || payment || arcgis ? (
                      <ArrowNarrowDownIcon />
                    ) : (
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    );
                  })()}
                </span>
              </TableCell>
            ))}
            {RowComponent && <TableCell align="center">{actionHeaderText || 'Advanced'}</TableCell>}
          </TableRow>
        ))}
      </TableHead>
      <TableBody
        style={{
          ...(credit && { backgroundColor: theme.palette.primary[1400] }),
          ...(payment && { backgroundColor: theme.palette.primary[1400] }),
        }}
        {...table.getTableBodyProps()}
      >
        {table.rows.map((row) => {
          table.prepareRow(row);
          if (CustomizedRow) {
            return (
              <CustomizedRow
                row={row.original}
                handleClick={() => {
                  handleRowClick(row);
                }}
              />
            );
          }
          return (
            <TableRow
              key={`${v4()}${new Date().getMilliseconds()}`}
              selected={selectedRow === row.original.id}
              /* onDragStart={(e) => dragStart(e)}
              onDragEnter={(e) => dragEnter(e)}
              onDragEnd={drop} */
              draggable={dragAndDropEnabled === true}
            >
              {selectRow && (
                <TableCell style={styleBox}>
                  <Checkbox
                    id={`${v4()}-${row.original.id}`}
                    value={row.original.id}
                    checked={isRowSelected(row.original.id)}
                    onChange={() => handleRowSelection(row.original.id)}
                    color="secondary"
                    style={styleCheckBox}
                  />
                </TableCell>
              )}
              {row.cells.map((cell) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <TableCell
                  key={`${v4()}${new Date().getMilliseconds()}`}
                  onClick={() => {
                    handleRowClick(row);
                  }}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </TableCell>
              ))}
              {RowComponent && (
                <TableCell align="center">
                  <RowComponent row={row} handleConfirm={actionFunction} external={external} />
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

GeneralTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  table: PropTypes.object.isRequired,
  selectedRow: PropTypes.string,
  handleRowClick: PropTypes.func,
  rowComponent: PropTypes.any,
  actionFunction: PropTypes.func,
  handleSelectedIds: PropTypes.func,
  selectRow: PropTypes.bool,
  external: PropTypes.bool,
  actionHeaderText: PropTypes.string,
  autoHeight: PropTypes.bool,
  dragAndDropEnabled: PropTypes.bool,
  onDrop: PropTypes.func,
  customizedRow: PropTypes.any,
  credit: PropTypes.bool,
  payment: PropTypes.bool,
  arcgis: PropTypes.bool,
};

GeneralTable.defaultProps = {
  selectedRow: '',
  handleRowClick: () => {},
  rowComponent: null,
  actionFunction: () => {},
  handleSelectedIds: () => {},
  selectRow: false,
  external: false,
  actionHeaderText: '',
  autoHeight: false,
  dragAndDropEnabled: false,
  onDrop: () => {},
  customizedRow: null,
  credit: false,
  payment: false,
  arcgis: false,
};
