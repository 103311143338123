import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { DeleteIcon } from 'components/Icons/DeleteIcon';
import { ArrowTopRight } from 'components/Icons';
import { useStyles as useTopBarStyles } from 'components/TopBar/styles';
import { MapViewComponent } from 'pages/Admin/MapGis/MapViewComponent';
import { mapGisAction } from 'pages/Admin/MapGis/helpers';
import { ActiveMapGis } from '../ActiveMapGis/ActiveMapGis';
import { InfoDialog } from './InfoDialog';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import moment from 'moment';

export const MapCard = ({ data, handleAction }) => {
  const classes = useStyles();
  const classesTopBar = useTopBarStyles();

  return (
    <Card className={classes.container} elevation={0}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <Typography>{data.mapName}</Typography>
          <div className="icons">
            <ActiveMapGis on={data.isActive} webmap={data} />
            <InfoDialog />
          </div>
        </div>
        <div className={classes.mapBtnContainer}>
          <MapViewComponent mapData={data} />
          <div className={classes.actions}>
            <Button
              onClick={() => handleAction(mapGisAction.DELETE, data)}
              className={classesTopBar.button}
              color="primary"
              variant="contained"
              disableElevation
            >
              <DeleteIcon size={18} />
            </Button>
            <Button
              onClick={() => handleAction(mapGisAction.EDIT, data)}
              style={{ height: '40px', padding: '10px' }}
              color="primary"
              variant="contained"
              disableElevation
            >
              <Typography mr={0.5}>Edit</Typography> <ArrowTopRight size={14} />
            </Button>
          </div>
        </div>
        <div className={classes.description}>
          <Typography>{data.mapDescription}</Typography>
          <Typography style={{ color: '#B1B1B1' }}>{moment(data?.createdAt).format('MM/DD/YY')}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

MapCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired,
};
