import React from 'react';
import PropTypes from 'prop-types';
import { Svg } from './Svg';
import { Box } from '@mui/material';

const getFileTypeStyle = (type) => {
  const baseStyle = {
    padding: '2px 6px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 'bolder',
    width: 'fit-content',
  };

  const styles = {
    XLS: { backgroundColor: '#039855', color: 'white' },
    DOC: { backgroundColor: '#155EEF', color: 'white' },
    PDF: { backgroundColor: '#D92D20', color: 'white' },
  };

  return { ...baseStyle, ...styles[type] };
};

export const FilePageIcon = ({ type, size, className }) => {
  let fileType = type.split('/')[1].toUpperCase();

  switch (fileType) {
    case 'MSWORD':
      fileType = 'DOC';
      break;
    case 'XLSX':
      fileType = 'XLS';
      break;
    case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
      fileType = 'XLS';
      break;
    case 'SHEET':
      fileType = 'XLS';
      break;
    default:
      break;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Svg viewBox="0 0 40 40" size={size} className={className}>
        <path
          d="M0.75 4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883885L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4Z"
          stroke="#515151"
          strokeWidth="1.5"
          fill="none"
        />
        <path d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5" stroke="#515151" strokeWidth="1.5" fill="none" />
      </Svg>
      <Box sx={{ ...getFileTypeStyle(fileType), position: 'absolute', bottom: 12, left: -5 }}>{fileType}</Box>
    </div>
  );
};

FilePageIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

FilePageIcon.defaultProps = {
  size: 18,
  className: null,
};
