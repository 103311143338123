import React, { useState, useContext } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon, MoreThanIcon } from 'components/Icons';
import { ModalCreditsPurchasing } from 'smartComponents/ModalCreditsPurchasing';
import { Card } from 'components/Card';
import { SidebarDetailContext } from 'components/SidebarProviderDetail/SidebarProviderDetail';
import { AvailableCredits } from './AvailableCredits';
import { CreditUsageAnalysis } from './CreditUsageAnalysis';
import { CreditUsageDetails } from './CreditUsageDetails';
import { useStyles } from './styles';

export const CreditUsage = () => {
  const [expanded, setExpanded] = useState(false);
  const [creditsPurchasingModal, setCreditsPurchasingModal] = useState(false);
  const { config } = useContext(SidebarDetailContext);
  const component = config.header.find((item) => item.name === 'credits');
  const { actions } = component;
  const classes = useStyles();

  const onBuyCredits = () => setCreditsPurchasingModal(true);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className="title">
          <div>
            <div className={classes.titleOne}>Administration</div>
            <span className={classes.titleBreadcumb}>
              <MoreThanIcon size={16} />
            </span>
            <div className={classes.titleSubHeading}>Credit Use</div>
          </div>
          <Button
            onClick={() => actions.handleClose()}
            className={classes.headerButton}
            variant="contained"
            color="primary"
            disableElevation
          >
            <CloseIcon size={20} />
          </Button>
        </div>
      </div>

      <Card noPadding>
        <div style={{ height: 'auto', overflow: 'auto' }}>
          <div className={classes.topHeader}>
            <IconButton
              onClick={() => setExpanded(!expanded)}
              className={`${classes.borderTop} ${classes.borderBottom} ${classes.topHeaderIcon}`}
            >
              {expanded ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}
            </IconButton>
          </div>

          <div className={classes.generalContent}>
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={12} lg={8} xl={8}>
                <CreditUsageAnalysis expanded={expanded} />
              </Grid>

              <Grid item xs={12} sm={12} lg={4} xl={4}>
                <AvailableCredits onBuyCredits={onBuyCredits} expanded={expanded} />
              </Grid>

              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <CreditUsageDetails />
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
      {creditsPurchasingModal &&
        (process.env.REACT_APP_HIDE_BILLING === false || process.env.REACT_APP_HIDE_BILLING === 'false') && (
          <ModalCreditsPurchasing
            isOpen={creditsPurchasingModal}
            onConfirm={() => setCreditsPurchasingModal(false)}
            onCancel={() => setCreditsPurchasingModal(false)}
          />
        )}
    </div>
  );
};
