import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles({
  actions: {
    '& svg': {
      color: theme.palette.primary[400],
    },
  },
});
