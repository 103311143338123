import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    search: '',
    fileType: '',
    sort_by: {
      name: '',
      createdAt: '',
    },
    linkedTo: '',
    uploadedBy: '',
    byMapExtent: false,
    byDateRange: {
      from: null,
      to: null,
    },
    activated: false,
  },
  linkedFiles: [],
};

export const linkedFilesSlice = createSlice({
  name: 'linkedFiles',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setLinkedFiles: (state, action) => {
      state.linkedFiles = action.payload;
    },
  },
});

export const { setSearch, setFilters, setLinkedFiles } = linkedFilesSlice.actions;

export default linkedFilesSlice.reducer;
