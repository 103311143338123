/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.css';
import { ObservationModal } from 'components/Observation/ObservationModal/ObservationModal';
import { Button, Grid } from '@mui/material';
import { useStyles } from 'pages/Profile/SingleProject/ProjectVideoPlayer/styles';
import { ButtonCreateProjectAssetInspectionFinding } from 'components/ProjectDetail/ProjectAssets/ProjectAssetDetail/ProjectAssetInspections/ButtonCreateProjectAssetInspectionFinding';
import { AlertTriangleIcon, PencilIcon } from 'components/Icons';
import SplashImage from 'images/ContentSplash.png';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { assetType } from 'components/Inspections/helpers';
import { DialogConfirmDownload } from 'components/VideoPlayer/DialogConfirmDownload';
import { DialogMessage } from 'components/DialogMessage';
import ProjectService from 'services/ProjectService';
import { setSelectedSequence } from 'slices/profileProjectAssetReducer';

const CREDITS_ARCHIVE_VIEW = 3;

export const ImagePlayer = ({ points }) => {
  const classes = useStyles();
  const {
    player: { selectedSequence },
  } = useSelector((state) => state.profileProjectAssets);
  const [pointsList, setPointsList] = useState([]);
  const { detail } = useSelector((state) => state.projectAssetActiveInspections);
  const { projectAsset } = useSelector((state) => state.profileProjectAssets);

  const [isCreateObservationOpen, setIsCreateObservationOpen] = React.useState(false);
  const [ignoreArchive, setIgnoreArchive] = React.useState(false);
  const [viewDownload, setViewDownload] = React.useState(false);
  const [credits, setCredits] = React.useState(0);
  const [creditError, setCreditError] = React.useState(false);
  const dispatch = useDispatch();

  const SizeCal = () => {
    let numbers = [];
    if (projectAsset.sequences) {
      numbers = projectAsset.sequences.map((as) => as.asset.assetFiles.reduce((total, file) => total + file.size, 0));
    } else {
      numbers = [
        projectAsset.sequence && projectAsset.sequence.asset
          ? projectAsset.sequence.asset?.assetFiles?.reduce((total, file) => total + file.size, 0)
          : 0,
      ];
    }
    const summation =
      numbers.length > 0 ? numbers.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
    return Math.ceil(summation / 1000 / 1000 / 1000) * CREDITS_ARCHIVE_VIEW;
  };

  const handleCreateObservationOpen = () => {
    setIsCreateObservationOpen(true);
  };

  const handleCreateObservationClose = () => {
    setIsCreateObservationOpen(false);
  };

  const handleOnObservationClick = () => {
    setIsCreateObservationOpen(true);
  };

  useEffect(() => {
    setPointsList(points);
  }, [points]);

  useEffect(() => {
    setCredits(SizeCal());
    setIgnoreArchive(false);
  }, [projectAsset]);

  const handleViewAsset = async () => {
    // console.log(projectAsset);
    try {
      await ProjectService.viewArchive(projectAsset.projId, projectAsset.id, credits);
      setIgnoreArchive(true);
    } catch (e) {
      if (e.message.indexOf('code 402') >= 0) {
        setCreditError(true);
      }
    }
    setViewDownload(false);
    dispatch(setSelectedSequence(0));
  };

  return (
    <>
      {projectAsset.archived && !ignoreArchive && (
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ position: 'relative' }} className={classes.fullHeight}>
          <WidgetSplash
            alt="Archived File"
            title="This file is archived"
            cta="VIEW PHOTOS"
            image={SplashImage}
            onClick={() => setViewDownload(true)}
          />
        </Grid>
      )}
      {points && (!projectAsset.archived || ignoreArchive) && (
        <>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            className={classes.imageContent}
            src={
              pointsList.length > 0 &&
              pointsList[selectedSequence] &&
              pointsList[selectedSequence].assets?.signedURLPrefix.replace(':filename', 'thumbnail_1000.jpg')
            }
            alt={
              pointsList?.length > 0 &&
              pointsList[selectedSequence] &&
              pointsList[selectedSequence].assets?.assetFiles[0] &&
              pointsList[selectedSequence].assets.assetFiles[0].fileName
            }
          />
          <ButtonCreateProjectAssetInspectionFinding
            time={0}
            points={pointsList}
            action="creation"
            selectedSequence={selectedSequence}
            type={assetType.IMAGE}
          />
          {!detail?.id && (
            <Button
              sx={{ bottom: '5px', left: '5px', position: 'absolute' }}
              onClick={handleOnObservationClick}
              color="secondary"
              variant="contained"
              title="Create an Observation"
            >
              <PencilIcon size={20} />
            </Button>
          )}
          {isCreateObservationOpen && (
            <ObservationModal
              isOpen={isCreateObservationOpen}
              handleOpen={handleCreateObservationOpen}
              handleClose={handleCreateObservationClose}
              time={0}
              selectedSequence={selectedSequence}
              points={pointsList}
              action="creation"
              container="sidebar-panel"
            />
          )}
        </>
      )}
      <DialogConfirmDownload
        isOpen={viewDownload}
        onConfirm={handleViewAsset}
        onCancel={() => setViewDownload(false)}
        credits={credits}
        data={{ id: 'projectId' }}
        openPerms={projectAsset.ignorePerms}
      />
      <DialogMessage
        icon={AlertTriangleIcon}
        title="Not enough credits"
        content="Your account does not have enough credits to view this archived file."
        isOpen={creditError}
        confirmText="Ok"
        iconColor="warning"
        onConfirm={() => setCreditError(false)}
      />
    </>
  );
};
ImagePlayer.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sequenceId: PropTypes.number,
      sequenceIndex: PropTypes.number,
    })
  ),
};
ImagePlayer.defaultProps = {
  points: null,
};
