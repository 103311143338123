import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Typography, Button, Chip } from '@mui/material';
import { SquareArrow } from 'components/Icons/SquareArrow';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TopBar } from 'components/TopBar/TopBar';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { BillingActions } from 'components/BillingActions/BillingActions';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { Loader } from 'components/Loader/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { setPageFilter, setSearch, setCreditInfo, setDetailData, getCreditUsage } from 'slices/creditUsageReducer';
import { theme } from 'theme';
import moment from 'moment';
import { CreditUsageDetail } from './CreditUsageDetail';
import { SearchPanel } from '../SearchPanel';
import { useStyles } from '../styles';
import exportFromJSON from 'export-from-json';
import SplashImage from 'images/ContentSplash.png';

let debounceTimeout = null;

const tableColumns = () => [
  {
    Header: 'Type',
    accessor: (rowData) => {
      const credits = {
        Processing: theme.palette.secondary[500],
        Storage: theme.palette.secondary[1100],
        AI: theme.palette.secondary[600],
        Indexing: theme.palette.secondary[1000],
        Downloads: '#FF5C15',
        CreditsAdded: '#baf8ba',
        ArchiveView: '#FFE0B3',
      };
      return (
        <Chip
          variant="filled"
          label={`${rowData.credits_type}`}
          style={{
            fontSize: 13,
            backgroundColor: credits[rowData.credits_type],
            width: '120px',
          }}
        />
      );
    },
  },
  {
    Header: 'Use/Purchased Date',
    accessor: (rowData) => {
      const { created_date: createdAt } = rowData;
      return (
        <div
          style={{
            fontFamily: 'inter',
            color: theme.palette.primary[400],
            fontSize: '14px',
            fontStyle: 'normal',
          }}
        >
          {moment(createdAt).format('MM/DD/YY')}
        </div>
      );
    },
  },
  {
    Header: 'Credits Used/Added',
    accessor: (rowData) => {
      const { credits_used: creditUsed } = rowData;
      return (
        <div
          style={{
            fontFamily: 'inter',
            color: theme.palette.primary[400],
            fontSize: '14px',
            fontStyle: 'normal',
          }}
        >
          {`${
            creditUsed === 0
              ? parseFloat('1').toFixed(2)
              : creditUsed.toLocaleString('en-US', {
                  numberingSystem: true,
                  maximumFractionDigits: 2,
                })
          }` || ''}
        </div>
      );
    },
  },
];

export const CreditUsageDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = React.useMemo(() => tableColumns(), []);
  const [selectedRow, setSelectedRow] = useState('');
  const { records, filters, loading, totalCount } = useSelector((state) => state.creditUsage);
  // display the search panel conponent
  const { setHeaderPanels, displayPanel, setFullWidth } = useSidebar({
    open: false,
    config: {
      onClose: () => {},
      search: {
        show: true,
        component: SearchPanel,
      },
    },
  });

  const table = useTable(
    {
      columns,
      data: records,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      pageLength: filters.page_size,
      totalCount,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const exportData = () => {
    const credits = [...records];
    if (credits.length) {
      exportFromJSON({
        data: credits,
        fileName: `CartoVid_Credit_Usage_${moment(new Date()).format('YYYY-MM-DD_HH:MM:SS')}`,
        exportType: exportFromJSON.types.csv,
        delimiter: ',',
      });
    }
  };

  const {
    state: { pageIndex, pageSize },
  } = table;

  const handleSearch = (e) => dispatch(setSearch(e.target.value));

  const handleRowClick = (row) => {
    const credit = row?.original ? row?.original : row;
    setSelectedRow(row.id);
    dispatch(setDetailData([]));
    dispatch(setCreditInfo(credit));

    // show credits detail
    setHeaderPanels([
      {
        name: 'creditUsageDetail',
        component: CreditUsageDetail,
        actions: {
          handleClose: () => {
            displayPanel('');
          },
        },
      },
    ]);
    setFullWidth(true);
    displayPanel('creditUsageDetail');
  };

  useEffect(() => {
    dispatch(setSearch());
  }, []);

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  // call the table data
  useEffect(() => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      dispatch(getCreditUsage(filters));
    }, 300);
  }, [filters]);

  const CreditsContainer = () => (
    <>
      <TopBar
        title="Credit Use Details"
        searchValue={filters?.search}
        handleSearch={handleSearch}
        activatedFilter={filters.activated}
        filterButton
        credit
      />
      <GeneralTable
        table={table}
        selectedRow={selectedRow}
        actionFunction={(credit) => handleRowClick(credit)}
        rowComponent={BillingActions}
        external={false}
        credit
      />
      <TablePagination table={table} position="end" credit />
    </>
  );

  const EmptyCreditsContainer = () => (
    <WidgetSplash image={SplashImage} alt="Credits Splash" title="There are currently no credits." />
  );

  return (
    <div className={classes.content}>
      {loading ? (
        <Loader loading={loading} height={150} size={32} />
      ) : (
        <>
          <div className={`${classes.borderTop} ${classes.headerDetail}`}>
            <div className={`${classes.headerDetailText} ${classes.titleOne}`}>Credit Use Details</div>

            <div className={classes.headerDetailButton}>
              <Button
                onClick={() => exportData()}
                disabled={!records.length}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
              >
                <SquareArrow />
                <Typography sx={{ fontSize: 14, marginLeft: '7px' }}>Export</Typography>
              </Button>
            </div>
          </div>

          <div className={`${classes.borderBottom} ${classes.contentFooter}`}>
            {records?.length ? <CreditsContainer /> : <EmptyCreditsContainer />}
          </div>
        </>
      )}
    </div>
  );
};
