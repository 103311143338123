import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '45vh',
    borderRadius: 8,
    overflow: 'hidden',
  },
  map: {
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
});
