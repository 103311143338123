import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from '@mui/material';
import { AdminShieldIcon } from 'components/Icons';

export const MemberRolesModal = ({
  open,
  onClose,
  title,
  description,
  entityType, // 'project' or 'team'
  entities,
  selectedEntity,
  onEntityChange,
  members,
  memberRoles,
  onMemberRoleChange,
  onConfirm,
  rolesConfig,
}) => {
  const getEntityLabel = (entity) => {
    if (!entity) return '';
    return entity.name || entity.id;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        variant="h4"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
          pb: 0,
        }}
      >
        <AdminShieldIcon size={40} />
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'normal' }}>
            {description}
          </Typography>

          <FormControl fullWidth sx={{ mb: 1, mt: 1 }}>
            <Autocomplete
              id={`${entityType}-select-autocomplete`}
              disablePortal
              options={entities}
              value={selectedEntity}
              onChange={onEntityChange}
              getOptionLabel={getEntityLabel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={`Paste UUID or Select ${entityType}`}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <Table sx={{ mt: 1, backgroundColor: 'transparent' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '30%',
                    fontWeight: 'normal',
                    border: 0,
                  }}
                />
                {rolesConfig.map((role) => (
                  <TableCell
                    key={role.key}
                    align="center"
                    sx={{
                      fontSize: '12px',
                      color: '#fff',
                      fontWeight: 'normal',
                      border: 0,
                    }}
                  >
                    {role.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow
                  key={member.id}
                  sx={{
                    border: 0,
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      border: 0,
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {member.name}
                  </TableCell>
                  {rolesConfig.map((role) => (
                    <TableCell
                      key={role.key}
                      align="center"
                      sx={{
                        border: 0,
                      }}
                    >
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.7)',
                          '&.Mui-checked': {
                            color: '#FF9100',
                          },
                        }}
                        checked={memberRoles[member.id]?.[role.key] || false}
                        onChange={() => onMemberRoleChange(member.id, role.key)}
                        disabled={role.disabled}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          gap: '1em',
          padding: '2em',
        }}
      >
        <Button sx={{ width: '50%' }} onClick={onClose} variant="contained">
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: '#FF9100',
            color: '#000',
            '&:hover': {
              backgroundColor: '#cc7400',
            },
            width: '50%',
          }}
          onClick={onConfirm}
          variant="contained"
          disabled={!selectedEntity}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MemberRolesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(['project', 'team']).isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,

  onEntityChange: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  memberRoles: PropTypes.object.isRequired,
  onMemberRoleChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  rolesConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  selectedEntity: PropTypes.string.isRequired,
};
