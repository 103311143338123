import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import { Card } from 'components/Card';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { ContextMenu } from 'components/ContextMenu/ContextMenu';
import { ContextMenuButton } from 'components/ContextMenuButton';
import { ContextMenuList } from 'components/ContextMenuItems/ContextMenuList';
import { ContextMenuItem } from 'components/ContextMenuItem';
import { setSelectedProjects } from 'slices/projectReducer';
import { ProjectsFilters } from './ProjectsFilters';
import PropTypes from 'prop-types';
import { WidgetDivider } from 'components/WidgetDivider/WidgetDivider';
import { CopyUuidAlert } from 'components/AddUuid/CopyUuidAlert/CopyUuidAlert';
import { AlertTriangleIcon } from 'components/Icons/AlertTriangleIcon';
import ProjectService from 'services/ProjectService';

export const Projects = ({ org, onInit }) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Created',
        accessor: 'created',
      },
      {
        Header: 'Size',
        accessor: 'size',
      },
      {
        Header: 'Files',
        accessor: 'files',
      },
      {
        Header: 'Observations',
        accessor: 'observations',
      },
      {
        Header: 'Participants',
        accessor: 'participants',
      },
    ],
    []
  );

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ProjectService.getProjectsOrganizations(org.id);
      const formattedProjects = data.map((project) => ({
        id: project.id,
        name: project.name,
        memberCount: project.memberCount,
        description: project.description,
      }));
      setProjects(formattedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  }, [org.id]);

  useEffect(() => {
    fetchProjects();
    if (onInit) {
      onInit(fetchProjects);
    }
  }, [fetchProjects, onInit]);

  const table = useTable(
    {
      columns,
      data: projects,
    },
    useSortBy
  );

  const handleSelectedIds = (ids) => {
    setSelectedRows(ids);
    dispatch(setSelectedProjects(ids));
  };

  const handleCopyUuid = (uuid) => {
    setSelectedUuid(uuid);
    setShowCopyModal(true);
  };

  const handleCloseCopyModal = () => {
    setShowCopyModal(false);
    setSelectedUuid(null);
  };

  const handleDeleteProject = async () => {
    try {
      if (projectToDelete) {
        await ProjectService.deleteProject(projectToDelete.id);
        setProjects(projects.filter((project) => project.id !== projectToDelete.id));
        setShowDeleteConfirm(false);
        setProjectToDelete(null);
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleOpenDeleteConfirm = (project) => {
    setProjectToDelete(project);
    setShowDeleteConfirm(true);
  };

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [projectRoles, setProjectRoles] = useState({});

  const handleManageMembers = () => {};

  const handleManageProjectParticipation = () => {};

  const handleManageTeamParticipation = () => {};

  const handleEditProject = () => {};

  const ProjectActions = ({ row }) => (
    <ContextMenu>
      <ContextMenuButton />
      <ContextMenuList position="bottom-right">
        <ContextMenuItem onClick={() => handleCopyUuid(row.original.id)}>Copy UUID</ContextMenuItem>
        <ContextMenuItem onClick={() => handleManageMembers(row.original)}>Manage Members</ContextMenuItem>
        <ContextMenuItem onClick={() => handleManageProjectParticipation(row.original)}>
          Manage Project Participation
        </ContextMenuItem>
        <ContextMenuItem onClick={() => handleManageTeamParticipation(row.original)}>
          Manage Team Participation
        </ContextMenuItem>
        <ContextMenuItem onClick={() => handleEditProject(row.original)}>Edit Name/Descriptions/Tags</ContextMenuItem>
        <WidgetDivider />
        <ContextMenuItem onClick={() => handleOpenDeleteConfirm(row.original)}>Delete Project</ContextMenuItem>
      </ContextMenuList>
    </ContextMenu>
  );

  return (
    <Card noPadding>
      <ProjectsFilters
        selectedRows={selectedRows}
        org={org}
        onProjectsUpdate={fetchProjects}
        projects={projects}
        showProjectModal={showProjectModal}
        setShowProjectModal={setShowProjectModal}
        showTeamModal={showTeamModal}
        setShowTeamModal={setShowTeamModal}
        projectRoles={projectRoles}
        setProjectRoles={setProjectRoles}
      />
      <GeneralTable
        table={table}
        selectRow
        handleSelectedIds={handleSelectedIds}
        rowComponent={ProjectActions}
        loading={loading}
      />
      {showCopyModal && <CopyUuidAlert id={selectedUuid} handleClose={handleCloseCopyModal} />}

      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)} maxWidth="sm" fullWidth>
        <Box sx={{ mt: 1, ml: 1 }}>
          <AlertTriangleIcon size={28} stroke="#e57373" background="#fff9c4" />
        </Box>
        <DialogTitle variant="h4">Delete {projectToDelete?.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Do you really want to delete {projectToDelete?.name} from the organization? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)} variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDeleteProject} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

Projects.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onInit: PropTypes.func,
};

Projects.defaultProps = {
  onInit: null,
};
