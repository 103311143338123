import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { UserPlusIcon } from 'components/Icons/UserPlusIcon';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { TrashIcon, PlusIcon } from 'components/Icons';
import OrganizationService from 'services/OrganizationService';

const UserEntry = ({ user, onRemove, orgId, orgName, onSelect, selected }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 0.5 }}>
    <Checkbox checked={selected} onChange={(e) => onSelect(user.email, e.target.checked)} color="secondary" />
    <Box sx={{ flex: 1 }}>
      <Typography variant="body2">{user.name}</Typography>
      <Typography variant="caption">{user.email}</Typography>
    </Box>
    <Select value={orgId} sx={{ width: 200 }} disabled>
      <MenuItem value={orgId}>{orgName}</MenuItem>
    </Select>
    <IconButton onClick={() => onRemove(user.email)} size="small">
      <TrashIcon size={15} fill="#fff" />
    </IconButton>
  </Box>
);

export const ModalAddUsers = ({ isOpen, onCancel, onConfirm, orgId, orgName }) => {
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '' });
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());

  const handleAddUser = () => {
    if (newUser.firstName && newUser.lastName && newUser.email) {
      const userToAdd = {
        name: `${newUser.firstName} ${newUser.lastName}`,
        email: newUser.email,
        orgId,
      };
      setUsers([...users, userToAdd]);
      setSelectedUsers(new Set([...selectedUsers, userToAdd.email])); // Auto-select new users
      setNewUser({ firstName: '', lastName: '', email: '' });
    }
  };

  const handleRemoveUser = (email) => {
    setUsers(users.filter((user) => user.email !== email));
    const newSelected = new Set(selectedUsers);
    newSelected.delete(email);
    setSelectedUsers(newSelected);
  };

  const handleSelectUser = (email, isSelected) => {
    const newSelected = new Set(selectedUsers);
    if (isSelected) {
      newSelected.add(email);
    } else {
      newSelected.delete(email);
    }
    setSelectedUsers(newSelected);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(new Set(users.map((user) => user.email)));
    } else {
      setSelectedUsers(new Set());
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const selectedUsersList = users.filter((user) => selectedUsers.has(user.email));
      const data = {
        members: selectedUsersList.map((user) => ({
          firstName: user.name.split(' ')[0],
          lastName: user.name.split(' ')[1],
          email: user.email,
        })),
      };
      await OrganizationService.addNewMembers(orgId, data);
      onConfirm();
    } catch (error) {
      console.error('Error adding users:', error);
    } finally {
      setLoading(false);
    }
  };

  const isAllSelected = users.length > 0 && selectedUsers.size === users.length;

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      {loading && <LoadingOverlay />}
      <DialogTitle>
        <ModalHeaderIcon icon={UserPlusIcon} text="Add Users" color="success" />
        <Typography mt={0.5}>Add new users to the Account</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 1 }}>
          {users.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selectedUsers.size > 0 && selectedUsers.size < users.length}
                onChange={handleSelectAll}
                color="secondary"
              />
              <Typography variant="body2">Select All</Typography>
            </Box>
          )}
          {users.map((user) => (
            <UserEntry
              key={user.email}
              user={user}
              onRemove={handleRemoveUser}
              orgId={orgId}
              orgName={orgName}
              onSelect={handleSelectUser}
              selected={selectedUsers.has(user.email)}
            />
          ))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between', width: '100%' }}>
            <TextField
              label="First Name"
              value={newUser.firstName}
              onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
              size="small"
              sx={{ flex: 1, width: '100%' }}
            />
            <TextField
              label="Last Name"
              value={newUser.lastName}
              onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
              size="small"
              sx={{ flex: 1, width: '100%' }}
            />
          </div>
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            size="small"
            sx={{ flex: 1, width: '100%' }}
          />
          <Button
            onClick={handleAddUser}
            sx={{
              height: '40px',
              color: '#FF9100',
              display: 'flex',
              justifyContent: 'start',
              width: '90px',
            }}
          >
            <PlusIcon size={16} fill="#FF9100" />
            <span style={{ marginLeft: '1rem', fontweight: 'bold' }}>Add</span>
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, gap: 1 }}>
        <Button onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#FF9100',
            color: '#000',
            '&:hover': {
              backgroundColor: '#FF9100',
              opacity: 0.9,
            },
          }}
          disabled={selectedUsers.size === 0}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalAddUsers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

UserEntry.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    orgId: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};
