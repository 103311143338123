import ApiService from './ApiService';

export default class OrganizationService {
  static getOrganizations = (filters) => ApiService.get('/organizations', filters);

  static getSingleOrganization = (orgId) => ApiService.get(`/organizations/${orgId}`);

  static getOrganizationAdminsRoles = (orgId, orgRole) =>
    ApiService.get(`/organizations/${orgId}/users?participationLevel=${orgRole}`);

  static getOrganizationUserRoles = (orgId, userId) => ApiService.get(`/organizations/${orgId}/users/${userId}/roles`);

  // Used to Filter Organization Users
  static getOrganizationUsers = (orgId, filters) => ApiService.get(`/organizations/${orgId}/users?`, filters);

  /**
   * Get all members of an organization
   * @param {string} orgId - Organization ID
   * @returns {Promise} - API response with members data
   */
  static getOrganizationMembers = (orgId) => ApiService.get(`/organizations/${orgId}/members`);

  static saveOrganization = (data) => ApiService.post('/organizations', data);

  static addUser = (orgId, data) => ApiService.post(`/organizations/${orgId}/users`, data);

  static deactivateOrganization = async (orgId) => ApiService.post(`/organizations/${orgId}/deactivate`);

  /**
   * Update  the org roles for a user.
   *
   * @param {string} orgId
   * @param {string} userId
   * @param {number[]} roles
   * @returns
   */

  static updateUserRoles = async (orgId, userId, roles) =>
    ApiService.post(`/organizations/${orgId}/users/${userId}/roles`, { roles });

  /**
   * Update the org information.
   *
   * @param {string} orgId
   * @param {string[]} data
   * @returns
   */
  static updateOrganizationInfo = async (orgId, data) => ApiService.patch(`/organizations/${orgId}`, data);

  static getLatestUploads = async (orgId, filters) => ApiService.get(`organizations/${orgId}/latest-uploads`, filters);

  /**
   * Add new members to an organization
   * @param {string} orgId - Organization ID
   * @param {object} data - Array containing user IDs and roles
   * @returns {Promise} - API response with updated organization
   */
  static addNewMembers = async (orgId, data) => ApiService.post(`/organizations/${orgId}/members`, data);

  /**
   * Delete a member from an organization
   * @param {string} orgId - Organization ID
   * @param {string} userId - User ID to delete
   * @returns {Promise} - API response with updated organization
   */
  static deleteMember = async (orgId, userId) => ApiService.delete(`/organizations/${orgId}/members/${userId}`);

  /**
   * Bulk delete members from an organization
   * @param {string} orgId - Organization ID
   * @param {string[]} userIds - Array of user IDs to delete
   * @returns {Promise} - API response with updated organization
   */
  static bulkDeleteMembers = async (orgId, userIds) =>
    ApiService.post(`/organizations/delete/${orgId}/members`, { userIds });

  /**
   * Transfer members from one organization to another
   * @param {string} orgId - Organization ID
   * @param {data} data - Array of user IDs to transfer
   * @returns {Promise} - API response with updated organization
   */
  static transferMembers = async (orgId, data) => ApiService.post(`/organizations/${orgId}/members/transfer`, data);
}
