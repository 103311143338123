import React, { useState } from 'react';
import { DialogContent, Button, InputLabel, Grid, Chip } from '@mui/material';
import { useFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { FileDropZone } from 'components/FileDropZone/FileDropZone';
import { Loader } from 'components/Loader';
import { Form } from 'lib/form';
import { useStyles, StyledError } from './styles';
import { mapGisAction, mapGisFilterButtons } from 'pages/Admin/MapGis/helpers';
import MapGisService from 'services/MapGisService';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

export const Kml = ({ data, section, setPanel, handleOpen, handleClose }) => {
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // save the file and data
  const saveDataAndFile = async (form) => {
    setLoading(true);
    const { name, description, desiredLabel } = form;
    const lastDot = String(file.name).lastIndexOf('.');
    const type = String(file.name).substring(lastDot + 1);
    const body = {
      mapName: name,
      mapDescription: description,
      mapDesiredLabel: desiredLabel,
      mapLayerOn: true,
      mapType: type,
      isActive: true,
      serviceDesc: 'KML',
    };

    // setting the level
    if (section && section === mapGisFilterButtons.PROJ) {
      body.levelId = data?.id;
      body.levelDesc = mapGisFilterButtons.PROJ;
    }

    const response = await MapGisService.saveMapGisService(body);
    const google = await fetch(response?.data?.data?.uploadFileUrl, {
      method: 'PUT',
      body: file,
    });
    if (google.ok) {
      setLoading(false);
      handleOpen(false);
    }
  };

  // update data
  const updateData = async (form) => {
    setLoading(true);
    const { name, description, desiredLabel } = form;
    const body = {
      mapName: name,
      mapDescription: description,
      mapDesiredLabel: desiredLabel,
    };
    await MapGisService.updateMapGisService(data?.data?.id, body);
    setLoading(false);
    handleOpen(false);
  };

  // initial formik form
  const formik = useFormik({
    initialValues: {
      name: data?.data?.mapName || '',
      description: data?.data?.mapDescription || '',
      desiredLabel: data?.data?.mapDesiredLabel || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(10, 'Name must be at least 12 characters')
        .max(50, 'Name must be at most 50 characters')
        .required('Name is required'),
      description: Yup.string()
        .min(12, 'Description must be at least 12 characters')
        .max(255, 'Description must be at most 255 characters')
        .required('Description is required'),
      desiredLabel: Yup.string()
        .max(200, 'Desired Label must be at most 200 characters')
        .required('Desired Label is required'),
    }),
    onSubmit: async (form) => {
      if (data.action !== 'edit') {
        if (!file) {
          setFileError(true);
        } else {
          saveDataAndFile(form);
        }
      } else {
        updateData(form);
      }
    },
  });

  return (
    <>
      <DialogContent>
        <Grid container>
          {loading ? (
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
              <Loader loading={loading} height={50} size={32} />
            </div>
          ) : (
            <>
              {/* file */}
              {data?.action !== mapGisAction.EDIT && (
                <Grid xs={12} sm={12} lg={12} sx={12} item>
                  <div style={{ margin: '16px 24px' }}>
                    <FileDropZone
                      onAddFile={(value) => {
                        setFile(value);
                        setFileError(false);
                      }}
                      accept={['.kml', '.kmz']}
                      multiple={false}
                      arcgis
                    />
                  </div>
                  {file && (
                    <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                      <Chip label={file?.name} variant="filled" color="primary" onDelete={() => setFile(null)} />
                    </div>
                  )}
                  {fileError && <StyledError>File is required</StyledError>}
                </Grid>
              )}

              {/* name */}
              <Grid xs={12} sm={12} lg={12} sx={12} item>
                <InputLabel className={classes.inputLabel}>Name</InputLabel>
                <FormInput
                  id="name"
                  name="name"
                  value={formik?.values?.name}
                  onChange={formik?.handleChange}
                  onBlur={formik.handleBlur}
                  {...Form.fieldErrorHelper(formik, 'name')}
                  required
                  fullWidth
                />
              </Grid>

              {/* description */}
              <Grid xs={12} sm={12} lg={12} sx={12} item>
                <InputLabel className={classes.inputLabel}>Description</InputLabel>
                <FormInput
                  id="id"
                  name="description"
                  value={formik?.values?.description}
                  onChange={formik?.handleChange}
                  onBlur={formik.handleBlur}
                  {...Form.fieldErrorHelper(formik, 'description')}
                  required
                  fullWidth
                />
              </Grid>

              {/* desired label */}
              <Grid xs={12} sm={12} lg={12} sx={12} item>
                <InputLabel className={classes.inputLabel}>Desired Label</InputLabel>
                <FormInput
                  value={formik?.values?.desiredLabel}
                  onChange={formik?.handleChange}
                  onBlur={formik.handleBlur}
                  {...Form.fieldErrorHelper(formik, 'desiredLabel')}
                  helpText="The name field, open the KML in Notepad, find a field in Placemark tag, copy ad paste desired label"
                  id="desiredLabel"
                  name="desiredLabel"
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button
            onClick={() => {
              if (data.action === mapGisAction.EDIT) {
                handleClose();
              } else {
                setPanel(null);
              }
            }}
            disabled={loading}
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
          >
            {data.action === mapGisAction.EDIT ? 'Cancel' : 'Back'}
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button
            onClick={() => formik.handleSubmit()}
            disabled={loading}
            color="secondary"
            variant="contained"
            fullWidth
            disableElevation
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

Kml.propTypes = {
  data: PropTypes.object,
  section: PropTypes.string,
  setPanel: PropTypes.func,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

Kml.defaultProps = {
  data: {},
  section: '',
  setPanel: () => {},
  handleOpen: () => {},
  handleClose: () => {},
};
