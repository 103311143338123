import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const FiltersIcon = ({ size }) => (
  <Svg size={size} viewBox="0 0 20 18" fill="none">
    <path
      d="M4.16675 16.5V10.6667M4.16675 7.33333V1.5M10.0001 16.5V9M10.0001 5.66667V1.5M15.8334 16.5V12.3333M15.8334 9V1.5M1.66675 10.6667H6.66675M7.50008 5.66667H12.5001M13.3334 12.3333H18.3334"
      stroke="#B1B1B1"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

FiltersIcon.propTypes = {
  size: PropTypes.number,
};

FiltersIcon.defaultProps = {
  size: 20,
};
