import React, { useState } from 'react';
import { Drawer, Typography, TextField, Button, Box } from '@mui/material';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AccountService from 'services/AccountService';
import BillingService from 'services/BillingService';

export const SuperAdminPanel = ({ open, onClose }) => {
  const classes = useStyles();
  const [credits, setCredits] = useState('');
  const [contractDate, setContractDate] = useState('');
  const [contractLength, setContractLength] = useState('');
  const [totalSeats, setTotalSeats] = useState('');

  const handleAddCredits = async () => {
    if (credits !== '') {
      AccountService.updateCredits(parseFloat(credits));
      await BillingService.add({ noOfCreditsAdded: parseFloat(credits) });
    }
    setCredits('');
  };

  const handleSaveChanges = async () => {
    const values = {};
    if (contractDate !== '') {
      values.contractDate = contractDate;
    }
    if (contractLength !== '') {
      values.monthsOfContract = Number(contractLength);
    }
    if (totalSeats !== '') {
      values.seatLimit = Number(totalSeats);
    }
    if (Object.keys(values).length !== 0) {
      await AccountService.updateAccount(values);
    }
    setContractDate('');
    setContractLength('');
    setTotalSeats('');
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className={classes.drawer} BackdropProps={{ invisible: true }}>
      <Box sx={{ backgroundColor: '#363636', padding: 1 }}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Super Admin Control Panel</Typography>
          <CloseIcon className={classes.closeButton} onClick={onClose} />
        </Box>
        <Typography className={classes.subtitle}>Use your powers for good... not eeevil.. :)</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionLabel}>Add Credits</Typography>
        <TextField
          fullWidth
          placeholder="Enter amount"
          variant="outlined"
          value={credits}
          onChange={(e) => setCredits(e.target.value)}
          className={classes.input}
          type="number"
        />
        <hr style={{ margin: '15px 0px' }} />
        <Box className={classes.buttonContainer}>
          <Button className={classes.cancelButton} onClick={() => setCredits('')}>
            Cancel
          </Button>
          <Button variant="contained" className={classes.actionButton} onClick={handleAddCredits}>
            Add Credits
          </Button>
        </Box>
        <hr style={{ margin: '15px 0px' }} />
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionLabel}>Contract Start Date</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={contractDate}
          onChange={(e) => setContractDate(e.target.value)}
          className={classes.input}
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionLabel}>Contract Term (Months)</Typography>
        <TextField
          fullWidth
          placeholder="Enter months"
          variant="outlined"
          value={contractLength}
          onChange={(e) => setContractLength(e.target.value)}
          className={classes.input}
          type="number"
        />
      </Box>

      <Box className={classes.section}>
        <Typography className={classes.sectionLabel}>Total Seats</Typography>
        <TextField
          fullWidth
          placeholder="Enter seats"
          variant="outlined"
          value={totalSeats}
          onChange={(e) => setTotalSeats(e.target.value)}
          className={classes.input}
          type="number"
        />
      </Box>

      <Box className={classes.buttonContainer}>
        <Button className={classes.cancelButton} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" className={classes.actionButton} onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Box>
    </Drawer>
  );
};

SuperAdminPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
