import { makeStyles } from '@mui/styles';
import { theme } from 'theme';

export const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: 360,
      backgroundColor: '#1E1E1E',
      color: '#FFFFFF',
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: '#FFFFFF',
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  subtitle: {
    fontSize: '14px',
    color: '#A0A0A0',
    marginBottom: theme.spacing(1),
  },
  section: {
    padding: theme.spacing(1),
  },
  sectionLabel: {
    fontSize: '14px',
    color: '#FFFFFF',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#2A2A2A',
      color: '#FFFFFF',
      height: '40px',
      '& fieldset': {
        borderColor: '#404040',
        borderRadius: '4px',
      },
      '&:hover fieldset': {
        borderColor: '#505050',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '& input': {
        color: '#FFFFFF',
        fontSize: '14px',
        padding: '8px 12px',
        '&::placeholder': {
          color: '#808080',
          opacity: 1,
        },
      },
    },
    '& .MuiInputLabel-root': {
      display: 'none', // Hide the floating label
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: '#2A2A2A',
    flex: 1,
    height: '40px',
    textTransform: 'none',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#3A3A3A',
    },
  },
  actionButton: {
    flex: 1,
    backgroundColor: theme.palette.secondary.main,
    height: '40px',
    textTransform: 'none',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    color: '#000',
  },
}));
