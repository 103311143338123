import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { ModalHeaderIcon } from 'components/ModalHeaderIcon/ModalHeaderIcon';
import { Loader } from 'components/Loader/Loader';
import { ShieldIcon } from 'components/Icons';
import { useStyles } from './styles';
import { ModalConfirmPayment } from './ModalConfirmPayment';
import PropTypes from 'prop-types';
import CreditCardIcon from '../../images/CreditCardIcon.png';
import BillingService from 'services/BillingService';

export const ModalCreditsPurchasing = ({ onConfirm, onCancel, isOpen }) => {
  const [value, setValue] = useState('');
  const [openPaymentPending, setOpenPaymentPending] = useState('');
  const [selected, setSelected] = useState({ credits: '', equivalent: '' });
  const classes = useStyles();

  const availableCredits = (option) => {
    const options = {
      10000: { credits: '10,000 credits', equivalent: '$100' },
      50000: { credits: '50,000 credits', equivalent: '$500' },
      100000: { credits: '100,000 credits', equivalent: '$1,000' },
      500000: { credits: '500,000 credits', equivalent: '$5,000' },
      1000000: { credits: '1,000,000 credits', equivalent: '$9,900 (1% discount)' },
      5000000: { credits: '5,000,000 credits', equivalent: '$48,750 (2% discount)' },
      10000000: { credits: '10,000,000 credits', equivalent: '$97,000 (3% discount)' },
    };
    return options[option];
  };

  const handleCheckout = async (priceId) => {
    await BillingService.create({
      priceId,
      credits: Number(value),
      redirect: window.location.href,
    }).then((response) => {
      const { transactionId, paymentUrl } = response.data?.data;
      if (transactionId) {
        window.location.assign(paymentUrl);
      }
    });
  };

  const goToCheckout = () => {
    let prices = {};
    const pricesDev = {
      10000: 'price_1QSepyHGsnhECh4ZMeoBx0J3',
      50000: 'price_1QSf3cHGsnhECh4Zbtl9gAYA',
      100000: 'price_1QSf4nHGsnhECh4ZHBBV887D',
      500000: 'price_1QSf7rHGsnhECh4ZhAJc7XHS',
      1000000: 'price_1QSfC1HGsnhECh4ZlJqHjL2c',
      5000000: 'price_1QSfDsHGsnhECh4ZUgRhdew3',
      10000000: 'price_1QSfFdHGsnhECh4ZEYlZgXII',
    };
    const pricesProd = {
      10000: 'price_1OPWfHHGsnhECh4ZyDNmOwKJ',
      50000: 'price_1OTuRGHGsnhECh4ZUofYloVc',
      100000: 'price_1OTuY5HGsnhECh4ZYHf5D4Wq',
      500000: 'price_1OTuZIHGsnhECh4ZiUBKopxj',
      1000000: 'price_1OPWulHGsnhECh4ZWTa0S8ed',
      5000000: 'price_1QmyS8HGsnhECh4ZIRAmTsbd',
      10000000: 'price_1OPWytHGsnhECh4Z0QH1Gtnw',
    };
    if (process.env.REACT_APP_ENV === 'production') {
      prices = pricesProd;
    } else {
      window.console.log(process.env.REACT_APP_ENV);
      prices = pricesDev;
    }
    handleCheckout(prices[value]);
    if (openPaymentPending === 'accepted') {
      setOpenPaymentPending('');
      onConfirm();
    }
  };

  const handleConfirmPayment = (confirm) => {
    setOpenPaymentPending(confirm);
    if (confirm === 'accepted') {
      goToCheckout();
    }
  };

  const handleSelectedOption = (e) => {
    const credits = e.target.value;
    const available = availableCredits(credits);
    setValue(credits);
    setSelected(available);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <img src={CreditCardIcon} alt="Credit Card Icon" />
        <ModalHeaderIcon icon={ShieldIcon} text="Purchase Credits" />
        <Typography>Select your desired number of credits.</Typography>
      </DialogTitle>

      <DialogContent>
        {openPaymentPending === 'accepted' ? (
          <>
            <Typography>Redirecting to the payment page, please wait.</Typography>
            <Loader loading={openPaymentPending === 'accepted'} height={50} size={32} />
          </>
        ) : (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="10000"
            name="radio-buttons-group"
            value={value}
            onChange={handleSelectedOption}
          >
            <FormControlLabel value="10000" control={<Radio />} label="10,000 credits / $100" />
            <FormControlLabel value="50000" control={<Radio />} label="50,000 credits / $500" />
            <FormControlLabel value="100000" control={<Radio />} label="100,000 credits  / $1,000" />
            <FormControlLabel value="500000" control={<Radio />} label="500,000 credits / $5,000" />
            <FormControlLabel value="1000000" control={<Radio />} label="1,000,000 credits / $9,900 (1% discount)" />
            <FormControlLabel value="5000000" control={<Radio />} label="5,000,000 credits / $48,750 (2% discount)" />
            <FormControlLabel value="10000000" control={<Radio />} label="10,000,000 credits / $97,000 (3% discount)" />
          </RadioGroup>
        )}
      </DialogContent>

      <div className={classes.actions}>
        <div className={classes.actionsContent} style={{ marginRight: 5 }}>
          <Button
            onClick={onCancel}
            disabled={openPaymentPending === 'accepted'}
            color="primary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
        </div>

        <div className={classes.actionsContent} style={{ marginLeft: 5 }}>
          <Button
            onClick={() => {
              if (openPaymentPending !== 'accepted') setOpenPaymentPending('pending');
            }}
            disabled={!value || openPaymentPending === 'accepted'}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Purchase
          </Button>
        </div>
      </div>
      {openPaymentPending && (
        <ModalConfirmPayment
          paymentPending={openPaymentPending}
          credit={selected}
          handleConfirm={handleConfirmPayment}
        />
      )}
    </Dialog>
  );
};

ModalCreditsPurchasing.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

ModalCreditsPurchasing.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
};
