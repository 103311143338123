import React from 'react';
import { useSelector } from 'react-redux';
import { setFilters } from 'slices/teamsReducer';
import { TeamsSearchPanel } from './TeamsSearchPanel';

export const SearchPanel = () => {
  const filters = useSelector((state) => state.teams.filters);

  return <TeamsSearchPanel filters={filters} setFilters={setFilters} />;
};
