const ROOT_SIZE = 10;
const ROOT_FONT_SIZE = ROOT_SIZE * 2;

const pxToRem = (px) => px / ROOT_SIZE;

const themeBase = {
  root: {
    rootSize: `${ROOT_SIZE}px`,
    rootFontSize: `${pxToRem(14)}rem`,
  },
  typography: {
    fontFamily: ['Inter'].join(','),
    h1: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(24)}rem`,
    },
    h2: {
      fontFamily: 'Lato',
      fontWeight: '300',
      fontSize: `${pxToRem(20)}rem`,
    },
    h3: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(17)}rem`,
    },
    subtitle: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(17)}rem`,
    },
    body: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: `${pxToRem(14)}rem`,
    },
    bold: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(14)}rem`,
    },
    small: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: `${pxToRem(12)}rem`,
    },
    buttonText: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(17)}rem`,
      lineHeight: '2',
    },
    buttonTextSmall: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(14)}rem`,
      lineHeight: '2',
    },
    linkText: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: `${pxToRem(14)}rem`,
      textDecoration: 'underline',
    },
  },
  palette: {
    primary: {
      base: '#3b3b3b',
      main: '#3b3b3b',
      light: '#626262',
      dark: '#2b2b2b',
      disabled: '#454646',
      100: '#FCFCFD',
      200: '#F7F7F7',
      300: '#E1E1E1',
      400: '#CFCFCF',
      500: '#B1B1B1',
      600: '#9E9E9E',
      700: '#7E7E7E',
      800: '#626262',
      900: '#515151',
      1000: '#383838',
      1100: '#363636',
      1200: '#3b3b3b',
      1300: '#2b2b2b',
      1400: '#222226',
      1500: '#1A1C1D',
      1600: '#262222', // background modal
      1700: '#3A3A3A',
    },
    secondary: {
      base: '#FF9100',
      main: '#FF9100',
      light: '#FFA826',
      dark: '#FF8600',
      disabled: '#7a6b4d',
      100: '#FFFCF6',
      200: '#FFF3E0',
      300: '#FFE0B3',
      400: '#FFCC80',
      500: '#FFB84D',
      600: '#FFA826',
      700: '#FF9900',
      800: '#FF9100',
      900: '#FF8600',
      1000: '#FF7C00',
      1100: '#FF6B00',
    },
    warning: {
      main: '#F79009',
      disabled: '#DC6803',
      100: '#FFFCF5',
      200: '#FFFAEB',
      300: '#FEF0C7',
      400: '#FEDF89',
      500: '#FEC84B',
      600: '#FDB022',
      700: '#F79009',
      800: '#DC6803',
      900: '#B54708',
      1000: '#93370D',
      1100: '#7A2E0E',
    },
    danger: {
      base: '#F04438',
      main: '#F04438',
      disabled: '#5f4343',
      100: '#FFFBFA',
      200: '#FEF3F2',
      300: '#FEE4E2',
      400: '#FECDCA',
      500: '#FDA29B',
      600: '#F97066',
      700: '#F04438',
      800: '#D92D20',
      900: '#B42318',
      1000: '#912018',
      1100: '#7A271A',
    },
    success: {
      base: '#12B76A',
      main: '#12B76A',
      disabled: '#57725a',
      100: '#F6FEF9',
      200: '#ECFDF3',
      300: '#D1FADF',
      400: '#A6F4C5',
      500: '#6CE9A6',
      600: '#32D583',
      700: '#12B76A',
      800: '#039855',
      900: '#027A48',
      1000: '#05603A',
      1100: '#054F31',
    },
    white: '#ffffff',
    lightGreen: '#d1fadf',
  },
  spacing(multiple) {
    return `${pxToRem(multiple * ROOT_FONT_SIZE)}rem`;
  },
};

export const theme = {
  ...themeBase,
  dialog: {
    widths: {
      xs: 444,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  inputs: {
    text: {
      default: {
        borderColor: themeBase.palette.primary[700],
        color: 'white',
        backgroundColor: themeBase.palette.primary.base,
        marginBottom: themeBase.spacing(1),
      },
      disabled: {
        borderColor: themeBase.palette.primary.disabled,
      },
      error: {
        borderColor: themeBase.palette.danger.base,
      },
    },
    checkBoxes: {
      disabled: {
        backgroundColor: themeBase.palette.primary.disabled,
      },
      checked: {
        backgroundColor: themeBase.palette.secondary.base,
      },
    },
  },
  card: {
    colors: {
      base: '#3f4751',
      text: '#67859e',
      border: '#3f536e',
      icon: '#8dabc4',
    },
  },
};
