import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { Avatar } from 'components/Avatar';
import { getInitials } from 'lib/generalFunctions';

export const MemberDetailPanel = ({ member }) => (
  <Box sx={{ p: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Avatar initials={getInitials(`${member?.firstName} ${member?.lastName}`)} size={40} />
      <Box sx={{ ml: 2 }}>
        <Typography variant="h6">
          {member?.firstName} {member?.lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {member?.email}
        </Typography>
      </Box>
    </Box>
  </Box>
);

MemberDetailPanel.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};
