import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  subHeader: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'inherit',
    color: 'white',
    backgroundColor: 'black',
  },
});
