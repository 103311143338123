import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, TextField } from '@mui/material';
import { FilterIcon, GridIcon, ListIcon, SearchIcon } from 'components/Icons';
import { SidebarContext } from 'components/SidebarProvider/SidebarProvider';
import { useStyles as useTopBarStyles } from 'components/TopBar/styles';
import { Filters } from 'components/Filters/Filters';
import { options } from 'pages/Admin/MapGis/helpers';
import { v4 } from 'uuid';

export const MapGisTopBar = ({
  searchValue,
  activatedFilter,
  buttons,
  handleSearch,
  handleToggle,
  handleLocalFilters,
  onChangeSelected,
}) => {
  const [tableToggle, setTableToggle] = useState(false);
  const [selectedButton, setSelectedButton] = useState('');
  const { setActivePanel, setFullWidth } = useContext(SidebarContext);
  const externalClasses = useTopBarStyles({ flex: 'space-between' });

  const handleLocalToggle = () => {
    setTableToggle(!tableToggle);
    handleToggle(!tableToggle);
  };

  return (
    <div className={externalClasses.bar}>
      <div>
        {!tableToggle ? (
          <Button
            color="primary"
            variant="contained"
            className={externalClasses.button}
            onClick={() => handleLocalToggle()}
            disableElevation
          >
            <ListIcon size={15} />
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className={externalClasses.button}
            onClick={() => handleLocalToggle()}
            disableElevation
          >
            <GridIcon size={15} />
          </Button>
        )}

        <ButtonGroup style={{ marginLeft: '5px', height: '40px' }}>
          {buttons.map((button) => (
            <Button
              onClick={() => {
                setSelectedButton(button?.value);
                handleLocalFilters({ level: button?.value });
              }}
              key={`${v4()}${new Date().getMilliseconds()}`}
              color={selectedButton === button?.value ? 'secondary' : 'primary'}
              variant="contained"
              disableElevation
            >
              {button?.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div className={externalClasses.filters}>
        <Filters onChangeSelected={onChangeSelected} options={options} />
        <TextField
          variant="outlined"
          placeholder="Search"
          className={externalClasses.input}
          InputProps={{
            startAdornment: <SearchIcon size={20} />,
          }}
          value={searchValue}
          onChange={handleSearch}
        />
        <Button
          size="medium"
          color="primary"
          variant="contained"
          className={`${externalClasses.filterButton} ${activatedFilter && externalClasses.activatedFilter}`}
          onClick={() => {
            setFullWidth(false);
            setActivePanel('search');
          }}
          disableElevation
        >
          <FilterIcon size={15} /> Filters {activatedFilter && 'On'}
        </Button>
      </div>
    </div>
  );
};

MapGisTopBar.propTypes = {
  searchValue: PropTypes.string,
  activatedFilter: PropTypes.bool,
  buttons: PropTypes.array,
  handleSearch: PropTypes.func,
  handleToggle: PropTypes.func,
  handleLocalFilters: PropTypes.func,
  onChangeSelected: PropTypes.func,
};

MapGisTopBar.defaultProps = {
  searchValue: '',
  activatedFilter: false,
  buttons: [],
  handleSearch: () => {},
  handleToggle: () => {},
  handleLocalFilters: () => {},
  onChangeSelected: () => {},
};
