export const mapGisAction = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const mapGisFilterButtons = {
  ACCT: 'ACCT',
  ORG: 'ORG',
  PROJ: 'PROJ',
};

export const mapGisFilterButtonsValues = {
  [mapGisFilterButtons.ACCT]: 'Account',
  [mapGisFilterButtons.ORG]: 'Organization',
  [mapGisFilterButtons.PROJ]: 'Project',
};

/**
 * This is an array of objects with the options to display in the sort component.
 * It's important to mantain this structure because is the required in the component.
 * `key`: Field into the object to sort.
 * `label`: Name to display in the list.
 * `direction`: Option to sort.
 */
export const options = [
  { key: 'mapName', label: 'Name A - Z', direction: 'asc' },
  { key: 'mapName', label: 'Name Z - A', direction: 'desc' },
  { key: 'createdAt', label: 'Newest - Oldest', direction: 'desc' },
  { key: 'createdAt', label: 'Oldest - Newest', direction: 'asc' },
  { key: 'levelDesc', label: 'Set-At Level', direction: 'asc' },
];
