import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { preloadFilterDatasets, setFilters } from 'slices/creditUsageReducer';
import { SidebarPanel, cleanObjectFilters } from 'components/SidebarPanel';
import { SearchList } from 'components/SidebarPanel/SearchList/SearchList';
import { CalendarModal } from 'components/CalendarModal';
import { ClearFilterAction } from 'components/SidebarPanel/ClearFilterAction';

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const filters = useSelector((state) => state.creditUsage.filters);
  const { types, loaded } = useSelector((state) => state.creditUsage.panels.search);
  const [filterActions, setFiltersActions] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    sort_by: {},
    types: [],
    date_range: null,
  });

  // update filters
  const updateFilter = (auxFilters) => {
    setLocalFilters({ ...auxFilters });
  };

  // update selected options
  const updateNormalFilters = (value, type) => {
    const auxFilters = { ...localFilters };
    auxFilters[type] = value;
    setLocalFilters({ ...auxFilters });
  };

  // clear filters
  const handleClearFilters = () => {
    const auxFilters = { filters };
    if (cleanObjectFilters(auxFilters)?.filters) {
      dispatch(
        setFilters({
          sort_by: {},
          search: '',
          types: [],
          date_range: null,
          activated: false,
        })
      );
    }
  };

  // apply filters
  const handleApplyFilters = () => {
    const auxFilters = { ...localFilters };
    auxFilters.sort_by = cleanObjectFilters(auxFilters.sort_by);
    dispatch(setFilters({ ...auxFilters, activated: filterActions.length > 0 }));
  };

  // create clear filters
  const createClearFilters = (clearFilters) => {
    const auxFilters = [];
    if (clearFilters?.types?.length > 0)
      auxFilters.push({ text: 'Clear By Type', callBack: () => updateNormalFilters([], 'types') });
    if (clearFilters.date_range != null && clearFilters.date_range)
      auxFilters.push({ text: 'Clear Date Range', callBack: () => updateNormalFilters(null, 'date_range') });
    setFiltersActions(auxFilters);
    return auxFilters;
  };

  useEffect(() => {
    createClearFilters(localFilters);
  }, [localFilters]);

  useEffect(() => {
    createClearFilters(filters);
    setLocalFilters(filters);
  }, [filters]);

  // preload filters
  useEffect(() => {
    dispatch(preloadFilterDatasets());
  }, []);

  return (
    <SidebarPanel
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
      title="Filters"
      subTitle="Apply filters to hone your credits returns"
      filters
    >
      <div className="searchBody">
        <ClearFilterAction actions={filterActions} />
        <Button color="secondary" variant="contained" onClick={() => setOpenCalendar(true)} className="marginTop">
          By Date Range
        </Button>
        <SearchList
          title="Types"
          filter="types"
          onChangeSelect={updateFilter}
          localFilters={localFilters}
          fullList={types}
          loaded={loaded}
        />
      </div>

      {openCalendar && (
        <CalendarModal
          rangeDate={localFilters.date_range}
          handleClose={() => setOpenCalendar(false)}
          handleConfirm={(rangeDate) => {
            if (rangeDate.length && rangeDate[0].startDate != null && rangeDate[0].endDate != null) {
              updateNormalFilters([rangeDate[0].startDate, rangeDate[0].endDate], 'date_range');
            }
            setOpenCalendar(false);
          }}
        />
      )}
    </SidebarPanel>
  );
};
