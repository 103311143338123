import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PermFormatter from 'lib/permFormatter';
import { SidebarPanel } from 'components/SidebarPanel';
import { TabPanel } from 'components/TabPanel';
import { updateProjectPermissions } from 'slices/authReducer';
import {
  setFilters as setObservationFilters,
  setObservation,
  setProjectObservations,
  setProjectKmls,
} from 'slices/observationsReducer';
import { ParticipantCoordination } from 'pages/Admin/Projects/ParticipantCoordination';
import { resetProjectTeams, setFilters as setPermissionsFilters, typeSearch } from 'slices/projectPermissionsReducer';
import { setFilters as setProjectAssetFilters } from 'slices/profileProjectAssetReducer';
import { ComingSoon } from 'components/ComingSoon/ComingSoon';
import { resetInspections } from 'slices/inspectionReducer';
import { ProjectHeaderDetail } from './ProjectHeaderDetail';
import { ProjectInfo } from './ProjectInfo/ProjectInfo';
import { ProjectSettings } from './ProjectSettings/ProjectSettings';
import { ProjectAssets } from './ProjectAssets/ProjectAssets';
import { ProjectObservations } from './ProjectObservations/ProjectObservations';
import { ProjectActiveInspections } from './ProjectActiveInspections/ProjectActiveInspections';
import { ProjectAssetContentContextProvider } from 'components/ProjectDetail/ProjectAssets/ProjectAssetContentContext';
import { ProjectMapGis } from './ProjectMapGis/ProjectMapGis';
import { useStyles } from './styles';
import { PROJECT_TABS } from './lib';
import { LinkedFiles } from './LinkedFiles/LinkedFiles';

export const ProjectDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTabValue] = useState(1);
  const [linkedFilesKey, setLinkedFilesKey] = useState(0);
  const auth = useSelector((state) => state.auth);
  const { loading, detail, projKmls } = useSelector((state) => state.allProjects.panels);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const inspectionId = params.get('inspectionId');
    if (inspectionId) {
      setTabValue(PROJECT_TABS.PROJECT_ACTIVE_INSPECTIONS);
    }
    if (detail?.id) {
      const { permissions } = auth;
      const projectPermissions = { ...permissions };
      projectPermissions.projects = {
        ...permissions.projects,
        ...PermFormatter.format(detail.permissions),
      };
      dispatch(updateProjectPermissions(projectPermissions.projects));
      dispatch(
        setProjectObservations({
          observations: detail.observations,
          projectId: detail.id,
        })
      );
      dispatch(setProjectKmls({ kmls: projKmls }));
    }
    return () => {
      dispatch(
        setObservationFilters({
          sort_by: {},
          assetId: '',
          activated: false,
          search: '',
          date_range: null,
        })
      );
      dispatch(
        setPermissionsFilters({
          sort_by: {},
          organizationId: '',
          accountId: '',
          projectRole: '',
          activated: false,
          search: '',
          viewOption: typeSearch.USER,
        })
      );
      dispatch(
        setProjectAssetFilters({
          pastId: '',
          teamId: '',
          userId: '',
          acctId: '',
          date_range: null,
          sort_by: {},
          type: '',
          ownership: '',
          contributorId: '',
          getExtent: false,
          activated: false,
        })
      );
      dispatch(setObservation({}));
      dispatch(resetProjectTeams());
      dispatch(resetInspections());
    };
  }, [detail]);

  const handleLinkedFileAdded = useCallback(() => {
    // force a refresh of the LinkedFiles component by changing its key
    setLinkedFilesKey((prev) => prev + 1);
  }, []);

  return (
    <ProjectAssetContentContextProvider>
      <SidebarPanel
        title="Project Details"
        header={
          !loading && (
            <ProjectHeaderDetail
              data={detail}
              tab={tab}
              setTabValue={(value) => setTabValue(value)}
              loading={loading}
              onLinkedFileAdded={handleLinkedFileAdded}
            />
          )
        }
        loading={loading}
        detail
      >
        <TabPanel value={tab} index={PROJECT_TABS.PROJECT_INFO}>
          <ProjectInfo data={detail} />
        </TabPanel>
        <TabPanel
          value={tab}
          index={PROJECT_TABS.PROJECT_ASSETS}
          className={`${classes.content} ${classes.borderAssets}`}
        >
          <ProjectAssets project={detail} />
        </TabPanel>
        <TabPanel
          value={tab}
          index={PROJECT_TABS.PROJECT_OBSERVATIONS}
          className={`${classes.content} ${classes.borderAssets}`}
        >
          <ProjectObservations project={detail} />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.PARTICIPANT_COORDINATION} className={classes.participantContent}>
          <ParticipantCoordination project={detail} detail />
        </TabPanel>
        <TabPanel
          value={tab}
          index={PROJECT_TABS.PROJECT_MAP_GIS}
          className={`${classes.content} ${classes.mapGisContent}`}
          style={{ padding: '0!important' }}
        >
          <ProjectMapGis project={detail} />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.PROJECT_ACTIVE_INSPECTIONS} className={classes.content}>
          <ProjectActiveInspections project={detail} />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.PROJECT_LINKED_FILES} className={classes.content}>
          <LinkedFiles key={linkedFilesKey} project={detail} />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.COMMING_SOON_1} className={classes.content}>
          <ComingSoon />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.COMMING_SOON_2} className={classes.content}>
          <ComingSoon />
        </TabPanel>
        <TabPanel value={tab} index={PROJECT_TABS.PROJECT_SETTINGS} className={classes.fullHeight}>
          <ProjectSettings project={detail} />
        </TabPanel>
      </SidebarPanel>
    </ProjectAssetContentContextProvider>
  );
};
