import { Typography } from 'components/Typography/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const getColor = (theme, error, disabled) => {
  if (disabled) {
    return theme.palette.primary[600];
  }

  if (error) {
    return theme.palette.danger.base;
  }

  return '#B1B1B1';
};

const StyledCaption = styled(Typography)`
  color: ${(props) => getColor(props.theme, props.error, props.disabled)};
  text-align: left;
  display: inline-block;
  margin-top: 3px;
`;

export const FormHelperText = ({ error, disabled, children }) => (
  <StyledCaption error={error} disabled={disabled} variant="small" as="div">
    {children}
  </StyledCaption>
);

FormHelperText.propTypes = {
  /**
   * Applies error styling
   */
  error: PropTypes.bool,
  /**
   * Applies disabled styling
   */
  disabled: PropTypes.bool,
  /**
   * Contains text
   */
  children: PropTypes.node,
};

FormHelperText.defaultProps = {
  error: false,
  disabled: false,
  children: '',
};
