/**
 * Custom hook to sort an array of objects
 */
import { useState } from 'react';

// Starting the hook
export const useFilters = () => {
  /**
   * Here is declared a local state to save the new sorted items
   */
  const [items, setItems] = useState([]);
  /**
   * Option to sort `ascending`
   */
  const ASC = 'asc';
  /**
   * Option to sort `descending`
   */
  const DESC = 'desc';

  /**
   * This function receive as first param the array with the object to sort
   * and the second param is an object with the options to sort the items,
   * for example:
   * ``` jsx
   * {
   * 	key: 'mapName',
   * 	direction: 'desc'
   * }
   * ```
   * @param {Array<Object>} data
   * @param {Object} sortConfig
   */
  const requestToSort = (data, sortConfig) => {
    /**
     * Creating a new copy of the array.
     */
    const sortableItems = [...data];
    /**
     * Validating if the `sortConfig` object is not null.
     */
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        /**
         * If the `direction` is equal to `ascending` then the items will be sorted
         * with the passed option.
         */
        if (sortConfig.direction === ASC) {
          return String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]), 'en', {
            numeric: true,
            sensitivity: 'base',
          });
        }
        /**
         * If the `direction` is equal to `descending` then the items will be sorted
         * with the passed option.
         */
        if (sortConfig.direction === DESC) {
          return String(b[sortConfig.key]).localeCompare(String(a[sortConfig.key]), 'en', {
            numeric: true,
            sensitivity: 'base',
          });
        }
        return 0;
      });
    }
    /**
     * When the array is sorted then will be asigned to the local state.
     */
    setItems(sortableItems);
  };

  /**
   * Returning the local state and the function to request the sort.
   */
  return { sortedItems: items, requestToSort };
};
