import React from 'react';
import { Svg } from './Svg';
import PropTypes from 'prop-types';

export const FeatureWarningIcon = ({ size }) => (
  <Svg size={size} viewBox="0 0 57 56" fill="none">
    <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
    <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" strokeWidth="8" />
    <path
      d="M28.9997 25.0002V29.0002M28.9997 33.0002H29.0102M27.5574 19.892L18.9898 34.0986C18.5146 34.8866 18.277 35.2806 18.3121 35.6039C18.3427 35.886 18.4966 36.1423 18.7355 36.309C19.0095 36.5002 19.4837 36.5002 20.4322 36.5002H37.5673C38.5158 36.5002 38.99 36.5002 39.2639 36.309C39.5028 36.1423 39.6568 35.886 39.6874 35.6039C39.7225 35.2806 39.4849 34.8866 39.0097 34.0986L30.4421 19.892C29.9686 19.1068 29.7319 18.7142 29.423 18.5824C29.1535 18.4673 28.8459 18.4673 28.5765 18.5824C28.2676 18.7142 28.0309 19.1068 27.5574 19.892Z"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

FeatureWarningIcon.propTypes = {
  size: PropTypes.number,
};

FeatureWarningIcon.defaultProps = {
  size: 57,
};
