import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination, useSortBy, useTable } from 'react-table';
import { setSearch, setPageFilter, setFilters, setGisServices, setSortedData } from 'slices/mapGisReducer';
import { GeneralTable } from 'components/GeneralTable/GeneralTable';
import { TablePagination } from 'components/TablePagination/TablePagination';
import { WidgetSplash } from 'components/WidgetSplash/WidgetSplash';
import { Loader } from 'components/Loader';
import { useSidebar } from 'components/SidebarProvider/SidebarProvider';
import { MapViewComponent } from 'pages/Admin/MapGis/MapViewComponent';
import { SearchPanel } from 'pages/Admin/MapGis/SearchPanel';
import { mapGisAction, mapGisFilterButtonsValues } from 'pages/Admin/MapGis/helpers';
import { ModalLink } from 'smartComponents/ModalLinkToMapOrServices';
import { useFilters } from 'hooks/use-filters';
import { ActiveMapGis } from './ActiveMapGis/ActiveMapGis';
import { MapGisTopBar } from './MapGisTopBar/MapGisTopBar';
import { MapCard } from './MapCard/MapCard';
import { MapGisActions } from './MapGisActions/MapGisActions';
import { ModalDeleteMapGis } from './ModalDeleteMapGis/ModalDeleteMapGis';
import { useStyles } from './styles';
import SplashImage from 'images/ContentSplash.png';
import PropTypes from 'prop-types';
import moment from 'moment';

const tableColumns = () => [
  {
    Header: 'Off/On',
    accessor: (rowData) => {
      const { isActive } = rowData;
      return (
        <div>
          <span>
            <ActiveMapGis on={isActive} webmap={rowData} />
          </span>
        </div>
      );
    },
  },
  {
    Header: 'Map/GIS Layer',
    accessor: (rowData) => rowData.mapName,
  },
  {
    Header: 'Description',
    accessor: (rowData) => {
      const { mapDescription } = rowData;
      return mapDescription || 'No Description Found.';
    },
  },
  {
    Header: 'Set-At-Level',
    accessor: (rowData) => {
      const { levelDesc } = rowData;
      return mapGisFilterButtonsValues[levelDesc];
    },
  },
  {
    Header: 'Date',
    accessor: (rowData) => moment(rowData.createdAt).format('MM/DD/YY'),
  },
];

export const MapGis = ({ section, project, loadData }) => {
  const [tableToggle, setTableToggle] = useState(true);
  const [action, setAction] = useState({});
  const [mapData, setMapData] = useState(null);
  const [openMapServices, setOpenMapServices] = useState(false);
  const [openDeletion, setOpenDeletion] = useState(false);
  const { data, filters, totalCount, loading } = useSelector((state) => state.adminMapGis);
  const { buttons, gisServices } = useSelector((state) => state.adminMapGis.panels);
  const { sortedItems, requestToSort } = useFilters(); // Here we are using the custom hook to sort the data
  const columns = React.useMemo(() => tableColumns(), []);
  const classes = useStyles();
  const dispatch = useDispatch();

  // Adding the filter component
  useSidebar({
    open: false,
    config: {
      search: {
        show: true,
        component: SearchPanel,
      },
    },
  });

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: filters.page - 1,
        pageSize: filters.page_size,
      },
      pageCount: Math.ceil(totalCount / filters.page_size),
      totalCount,
      pageLength: filters.page_size,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const {
    state: { pageIndex, pageSize },
  } = table;

  const handleToggle = (toggle) => {
    setTableToggle(toggle);
  };

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const handleAction = (actionValue, row) => {
    setAction({ action: actionValue, data: row });
    if (actionValue === mapGisAction.DELETE) {
      setOpenDeletion(true);
    }
  };

  const handleRowClick = ({ original }) => {
    setMapData(original);
  };

  const handleLocalFilters = (values) => {
    dispatch(setFilters({ ...values }));
  };

  useEffect(() => {
    dispatch(setPageFilter(pageIndex + 1));
  }, [pageIndex, pageSize]);

  // get value from the state to open the modal link
  useEffect(() => {
    if (gisServices) {
      setOpenMapServices(gisServices);
      setAction(project);
    }
  }, [gisServices]);

  // set the first row
  useEffect(() => {
    if (data.length) {
      setMapData(data[0]);
    }
  }, [data]);

  /**
   * Here is watching the `sortedItems` variable to get
   * the new changes and dispatched it to the state to
   * update with the new data
   */
  useEffect(() => {
    dispatch(setSortedData(sortedItems));
  }, [sortedItems]);

  if (loading && data.length === 0) {
    return <Loader loading={loading} height={250} />;
  }

  const EmptyMapGisContainer = () => (
    <WidgetSplash alt="Project Gis/Map Splash" title="There are no maps" image={SplashImage} />
  );

  const Content = () => (
    <div className={classes.container}>
      <MapGisTopBar
        handleToggle={handleToggle}
        handleSearch={handleSearch}
        buttons={buttons.items}
        activatedFilter={filters?.activated}
        searchValue={filters?.search}
        handleLocalFilters={handleLocalFilters}
        onChangeSelected={(item) => requestToSort(data, item)} // Here we can call the hook `requestToSort` function and sending the data and the option to sort
      />
      {loading && <Loader height={50} loading size={32} />}
      {!loading && (
        <>
          <div style={{ display: tableToggle ? 'flex' : 'none' }} className={classes.container}>
            <MapViewComponent mapData={mapData} />
            <GeneralTable
              table={table}
              selectedRow={mapData?.id}
              rowComponent={MapGisActions}
              actionFunction={(event, row) => handleAction(event, row)}
              handleRowClick={handleRowClick}
              arcgis
            />
            <TablePagination table={table} />
          </div>
          <Grid
            style={{ display: tableToggle ? 'none' : 'flex' }}
            className={classes.cardsContainer}
            spacing={1}
            container
          >
            {data?.length &&
              data &&
              data.map((item) => (
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <MapCard data={item} handleAction={handleAction} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </div>
  );

  return (
    <>
      {data.length ? Content() : <EmptyMapGisContainer />}
      {/* modals */}
      <ModalLink
        handleOpen={(value) => {
          setOpenMapServices(value);
          dispatch(setGisServices(value));
          setAction({});
        }}
        onClose={() => loadData()}
        open={openMapServices || action?.action === mapGisAction.EDIT}
        data={action}
        section={section}
      />
      <ModalDeleteMapGis
        handleOpen={(value, refresh) => {
          if (refresh) {
            loadData();
          }
          setOpenDeletion(value);
        }}
        open={openDeletion}
        data={action?.data}
      />
    </>
  );
};

MapGis.propTypes = {
  section: PropTypes.string,
  project: PropTypes.object,
  loadData: PropTypes.func,
};

MapGis.defaultProps = {
  section: '',
  project: {},
  loadData: () => {},
};
