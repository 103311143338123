import PropTypes from 'prop-types';
import React from 'react';
import { Svg } from './Svg';

export const PlusIcon = ({ size, fill = 'none' }) => (
  <Svg viewBox="0 0 24 24" size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 10.996h-8.997V2h-2.006v8.996H2v2.007h8.997V22h2.006v-8.997H22z"
      fill={fill}
    />
  </Svg>
);

PlusIcon.propTypes = {
  size: PropTypes.number,
};

PlusIcon.defaultProps = {
  size: 24,
};
